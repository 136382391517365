import axios from "./axios";

export const fetchQuestionnaireTypes = async (userID) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        let userData = localStorage.getItem("user_data");
        let id = "";
        if (userData) {
            userData = JSON.parse(userData);

            id = userData.id;
        }
        // formData.append("active", activeStatus);
        const response = await axios.get(
            `/members/QuestionerApis/getAllQuestionsAndQuestionTypesAndAnswers?user_id=${userID || id}`,
            {
                headers: headers,
            }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const fetchQuestionsByID = async (id) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        formData.append("question_type_id", +id);
        formData.append("status", true);
        const response = await axios.post(
            "/members/QuestionerApis/getAllQuestionsByQuestionTypeId",
            formData,
            {
                headers: headers,
            }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const saveAnswers = async (answersList, userID) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${access_token}`,
    };
    try {
        let userData = localStorage.getItem("user_data");
        let id = "";
        if (userData) {
            userData = JSON.parse(userData);

            id = userData.id;
        }

        answersList.unshift({
            user_id: userID || id,
        });
        const response = await axios.post(
            "/members/QuestionerApis/addListOfAnswers",
            answersList,
            {
                headers: headers,
            }
        );
        return response;
    } catch (err) {
        throw err;
    }
};
