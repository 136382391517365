import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

import homeLegend from "../../../assets/home-bg.jpg";

const Main = ({ onSignUp }) => {
	return (
		<div className={cn("section", styles.section)}>
			<div className={cn("container", styles.container)}>
				<div className={styles.previewContainer}>
					<div className={styles.preview}>
						<picture>
							<source
								media="(max-width: 767px)"
								srcSet={homeLegend}
							/>
							<img
								src={homeLegend}
								alt="Landing Img"
							/>
						</picture>
						<div className={styles.wrap}>
							<h1 className={cn("hero", styles.title, styles.capitalHeading)}>Mother Club</h1>
							<button
								className={cn("button button-brown", styles.button)}
								onClick={onSignUp}
							>
								Sign in
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Main;
