import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { toast } from "react-toastify";

import styles from "./Footer.module.sass";
import { Link } from "react-router-dom";
import Image from "../Image";
import Icon from "../Icon";
import Theme from "themeComponents/Theme";
import Form from "themeComponents/Form";

import {
    getCurrentsubscribeToNewsletter,
    subscribeToNewsletter,
} from "utils/authService";
import { updateNewsletterSubscriptionStatus } from "store/authSlice";

const items = [
    {
        title: "Ceremonies",
        url: "/ceremonies",
    },
    {
        title: "Bookings",
        url: "/bookings",
    },
    {
        title: "Wishlists",
        url: "/wishlists",
    },
];

const Footer = () => {
    const dispatch = useDispatch();

    const authState = useSelector((state) => state.auth.authState);
    const user = useSelector((state) => state.auth.user);

    const [email, setEmail] = useState("");
    const [loading, toggleLoading] = useState(false);

    const handleSubscribeToNewsletter = async (e) => {
        e.preventDefault();
        toggleLoading(true);
        try {
            const response = await subscribeToNewsletter(email);
            const userJSON = localStorage.getItem("user_data");
            const userParsed = JSON.parse(userJSON);
            userParsed.is_newsletter_subscriber = true;
            localStorage.setItem("user_data", JSON.stringify(userParsed));
            dispatch(updateNewsletterSubscriptionStatus(true));
            toast.success("Newsletter subscribtion sent successfully!");
        } catch (err) {
            console.log("err", err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            toggleLoading(false);
        }
    };

    const fetchCurrentSubscriber = async () => {
        const response = await getCurrentsubscribeToNewsletter();
        if (response.status === 200) {
            setEmail(response?.data?.data);
        }
    };

    useEffect(() => {
        if (user && user?.email) {
            fetchCurrentSubscriber();
        }
    }, [user]);

    return (
        <div className={styles.footer}>
            <div className={cn("container", styles.container)}>
                <div className={styles.row}>
                    <div className={styles.col}>
                        {/* <Link
							className={styles.logo}
							to="/"
						>
							<h3 className={styles.title}>
								Mother <br /> Club
							</h3>
						</Link> */}

                        <div className={styles.box}>
                            <Icon name="bulb" size="20" />
                            <span>Dark theme</span>
                            <Theme className={styles.theme} />
                        </div>
                    </div>

                    <div className={styles.col}>
                        <div className={styles.menu}>
                            {/* {items.map((x, index) => (
								<Link
									className={styles.link}
									to={x.url}
									key={index}
								>
									{x.title}
								</Link>
							))} */}
                        </div>
                    </div>
                    {authState && (
                        <div className={styles.col}>
                            <div className={styles.info}>
                                Join the MOTHER CLUB{" "}
                                <span role="img" aria-label="fire">
                                    🦆
                                </span>
                            </div>
                            <Form
                                className={styles.form}
                                value={email}
                                setValue={setEmail}
                                onSubmit={handleSubscribeToNewsletter}
                                placeholder="Enter your email"
                                type="email"
                                name="email"
                                icon="arrow-next"
                                disabled={loading}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.bottom}>
                    <div className={styles.copyright}>
                        Copyright © {new Date().getFullYear()} Mothers Club LLC.
                        All rights reserved
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
