import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Geocode from "react-geocode";
import cn from "classnames";
import { toast } from "react-toastify";

import styles from "./Ceremony.module.sass";
import Product from "../../themeComponents/Product";
import Description from "./Description";
import Map from "components/Map";
import Loader from "themeComponents/Loader";

import { fetchCeremonyById, toggleCeremonyFavoriteStatus } from "utils/ceremoniesService";
import { saveSelectedCeremony, updateFavoriteStatusForCeremony } from "store/ceremoniesSlice";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
Geocode.enableDebug();

const Ceremony = () => {
	const dispatch = useDispatch();
	const params = useParams();

	const [purchaseDone, setPurchaseDone] = useState(false);

	const selectedCeremony = useSelector((state) => state.ceremonies.selectedCeremony);

	const [options, setOptions] = useState([]);
	const [latitude, setLatitude] = useState(0);
	const [longitude, setLongitude] = useState(0);
	const [loading, setLoading] = useState(true);
	const [loadingMap, setLoadingMap] = useState(true);

	const fetchDataOnMount = async () => {
		const { ceremonyId } = params;
		setLoading(true);
		try {
			const response = await fetchCeremonyById(ceremonyId);
			dispatch(saveSelectedCeremony(response.data.data));
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		} finally {
			setLoading(false);
		}
	};

	const handleFavoriteStatusUpdate = async () => {
		const { ceremonyId } = params;
		try {
			const response = await toggleCeremonyFavoriteStatus(ceremonyId);
			if (response.data.message.includes("Un-Favorite Event")) {
				dispatch(updateFavoriteStatusForCeremony({ eventId: selectedCeremony.event_id, status: false }));
				toast.warning("Removed from Favorites Successfully!");
			} else {
				dispatch(updateFavoriteStatusForCeremony({ eventId: selectedCeremony.event_id, status: true }));
				toast.success("Added to Favorites Successfully!");
			}
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		}
	};

	useEffect(() => {
		if (selectedCeremony) {
			setLoadingMap(true);

			const newAddress = `${selectedCeremony.Address} ${selectedCeremony.state_Name}, ${selectedCeremony.country_Name}`;
			Geocode.fromAddress(newAddress).then(
				(response) => {
					const { lat, lng } = response.results[0].geometry.location;
					setLatitude(lat);
					setLongitude(lng);
					setLoadingMap(false);
				},
				(error) => {
					console.log("err loading coordinates", error);
					// setLatitude(1);
					// setLongitude(1);
					setLoadingMap(false);
				}
			);

			setOptions((oldOptions) => [
                {
                    title: `${selectedCeremony.Address} ${selectedCeremony.state_Name}, ${selectedCeremony.country_Name}`,
                    icon: "flag",
                },
            ]);

			if (selectedCeremony?.is_ceremony_outdated) {
				toast.error('This ceremony is out dated')
            }

		}
	}, [selectedCeremony]);

	useEffect(() => {
        fetchDataOnMount();
        return () => {
            dispatch(saveSelectedCeremony(null));
        };
    }, [purchaseDone]);

	const maps = (
		<>
			<h4 className={cn("h4", styles.title)}>Location details</h4>
			<section className={styles.mapsContainer}>
				<Map
					loading={loadingMap}
					zoom={10}
					defaultCenter={[latitude, longitude]}
					pointers={[
						{
							latitude,
							longitude,
						},
					]}
				/>
			</section>
		</>
	);

	if (loading) {
		return (
			<div className={cn(styles.section)}>
				<div className={cn("container", styles.container, styles.loaderContainer)}>
					<Loader className={styles.loader} />
				</div>
			</div>
		);
	}

	return (
        <>
            <Product
                classSection="section-mb64"
                urlHome="/ceremonies"
                title={selectedCeremony.event_Name}
                breadcrumbs={[]}
                options={options}
                gallery={selectedCeremony.event_img}
                favoriteStatus={selectedCeremony.is_favorite}
                handleFavoriteStatusUpdate={handleFavoriteStatusUpdate}
                type="cars"
                coordinates={{ latitude, longitude }}
            />
            <Description
                classSection="section"
                ceremony={selectedCeremony}
                maps={maps}
                setPurchaseDone={setPurchaseDone}
            />
        </>
    );
};

export default Ceremony;
