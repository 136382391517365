import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import styles from "./Map.module.sass";
import OutsideClickHandler from "react-outside-click-handler";
import Icon from "../Icon";

const Map = ({ className, coordinates }) => {
	const [visible, setVisible] = useState(false);
	return (
		<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
			<div className={cn(className, styles.location)}>
				<a
					className={cn("button-circle-stroke button-small", styles.button, {
						[styles.active]: visible,
					})}
					href={"https://www.google.com/maps/dir/Current+Location/" + coordinates.latitude + "," + coordinates.longitude}
					target="_blank"
					rel="noreferrer"
				>
					<Icon
						name="location"
						size="24"
					/>
				</a>
			</div>
		</OutsideClickHandler>
	);
};

export default Map;
