import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import TextInput from "themeComponents/TextInput";
import Loader from "themeComponents/Loader";

import { updatePassword, changeStatusOfNewsletterSubscription, getNewsLetterSubscriberOrNot } from "utils/authService";
import { updateNewsletterSubscriptionStatus } from "store/authSlice";

import styles from "./PersonalInfo.module.sass";

const Newsletter = () => {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);

	const [processing, setProcessing] = useState(false);
	const [status, setStatus] = useState(false);

	const handleNewsletterUnsubscribe = async () => {
		setProcessing(true);
		try {
			const updatedStatus = !status;
			setStatus(updatedStatus);
			const response = await changeStatusOfNewsletterSubscription(updatedStatus);
			const userJSON = localStorage.getItem("user_data");
			const userParsed = JSON.parse(userJSON);
			userParsed.is_newsletter_subscriber = updatedStatus;
			localStorage.setItem("user_data", JSON.stringify(userParsed));
			dispatch(updateNewsletterSubscriptionStatus(updatedStatus));
			toast.warning("Newsletter un-subscribed successfully!");
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		} finally {
			setProcessing(false);
		}
	};

	const fetchCurrentSubscriberStatus = async () => {
		try {
			const res = await getNewsLetterSubscriberOrNot();
			if (res.data.message === "Subscriber") {
				setStatus(true);
				const userJSON = localStorage.getItem("user_data");
				const userParsed = JSON.parse(userJSON);
				userParsed.is_newsletter_subscriber = true;
			}
		} catch (err) {
			console.log("err while fetching newsletter subscriber status", err);
		}
	};

	useEffect(() => {
		if (user) {
			fetchCurrentSubscriberStatus();
		}
	}, [user]);

	return (
		<section className={styles.section}>
			<div className={styles.head}>
				<div className={cn("h2", styles.title)}>Newsletter</div>
			</div>
			{status ? (
				<button
					className={cn("button", styles.button)}
					disabled={processing}
					onClick={handleNewsletterUnsubscribe}
				>
					{processing ? <Loader color="white" /> : "Unsubscribe"}
				</button>
			) : (
				<p>You are not subscribed to Newsletters.</p>
			)}
		</section>
	);
};

export default Newsletter;
