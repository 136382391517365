import { createBrowserRouter } from "react-router-dom";

import ProtectedLayout from "./layouts/ProtectedBasicLayout";
import Home from "./pages/home";
import Ceremony from "./pages/ceremony";
import Retreat from "./pages/retreat";
import Bookings from "./pages/bookings";
import Wishlists from "./pages/wishlists";
import AccountSettings from "./pages/accountSettings";
import Register from "./pages/register";
import Error404 from "./pages/Error404";
import ResetChangePassword from "pages/changePassword";
import NewsletterSubscription from "./pages/newsletter";
import QuestionnairePage from "pages/questionnaire";

const routes = createBrowserRouter([
    {
        path: "/",
        element: <ProtectedLayout />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "/register/",
                element: <Register />,
            },
            {
                path: "/members/frontend-members/reset-password-change/:token",
                element: <ResetChangePassword />,
            },
            {
                path: "/ceremony/:ceremonyId",
                element: <Ceremony />,
            },
            {
                path: "/retreat/:retreatId",
                element: <Retreat />,
            },
            {
                path: "/bookings",
                element: <Bookings />,
            },
            {
                path: "/wishlists",
                element: <Wishlists />,
            },
            {
                path: "/account-settings",
                element: <AccountSettings />,
            },
            {
                path: "/newsletter-subscription", // new page added
                element: <NewsletterSubscription />,
            },
            {
                path: "/questionnaire", // new page added
                element: <QuestionnairePage />,
            },
        ],
        errorElement: <Error404 />,
    },
]);

export default routes;
