import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Gallery.module.sass";
import Icon from "../../Icon";
import PhotoView from "../../PhotoView";

const Gallery = ({ className, items = [], type }) => {
	const [initialSlide, setInitialSlide] = useState(0);
	const [visible, setVisible] = useState(false);

	const itemsOnFront = items.length > 3 ? items.slice(0, 3) : items;

	const handleOpen = (index) => {
		setInitialSlide(index);
		setVisible(true);
	};

	return (
		<>
			<div className={cn(styles.gallery, className)}>
				<div
					className={cn(
						styles.list,
						{
							[styles.list1]: items.length === 1,
						},
						{
							[styles.list2]: items.length === 2,
						},
						{
							[styles.list3]: items.length >= 3,
						}
					)}
				>
					{itemsOnFront.map((x, index) => (
						<section className={cn(styles.imgContainer)}>
							<img
								key={index}
								src={x}
								alt="Product Details"
								onClick={() => handleOpen(index)}
							/>
						</section>
					))}
					{items.length > 3 && <div className={styles.moreIndicator}>{`${items.length - 3} +`}</div>}
				</div>
			</div>
			<PhotoView
				title="Spectacular views of Queenstown"
				initialSlide={initialSlide}
				visible={visible}
				items={items}
				onClose={() => setVisible(false)}
			/>
		</>
	);
};

export default Gallery;
