import React from "react";
import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../../themeComponents/Icon";

const options = [
	{
		title: "Put your car feature",
		icon: "stopwatch",
		color: "#92A5EF",
	},
	{
		title: "Put your car feature",
		icon: "car",
		color: "#8BC5E5",
	},
	{
		title: "Up to 6 people",
		icon: "user",
		color: "#FA8F54",
	},
	{
		title: "Put your car feature",
		icon: "candlesticks",
		color: "#58C27D",
	},
];

const Details = ({ className, description }) => {
	return (
		<div className={cn(className, styles.details)}>
			<h4 className={cn("h4", styles.title)}>Ceremony details</h4>

			{/* <div className={styles.profile}>
				<span>Car owner</span>
				<div className={styles.avatar}>
					<img
						src="/images/content/avatar.jpg"
						alt="Avatar"
					/>
				</div>
				<div className={styles.name}>Colleen Jast</div>
			</div> */}

			{/* <div className={styles.options}>
				{options.map((x, index) => (
					<div
						className={styles.option}
						key={index}
					>
						<div
							className={styles.icon}
							style={{ borderColor: x.color }}
						>
							<Icon
								name={x.icon}
								size="24"
							/>
						</div>
						<div className={styles.text}>{x.title}</div>
					</div>
				))}
			</div> */}

			<div className={styles.group}>
				<div className={styles.box}>
					<div className={styles.content}>
						{/* <h4>Description</h4> */}
						<p>{description}</p>
					</div>
					{/* <button className={cn("button-stroke button-small", styles.button)}>Show more</button> */}
				</div>
			</div>
		</div>
	);
};

export default Details;
