import cn from "classnames";

import styles from "./RegisterSkeleton.module.sass";

const RegisterSkeleton = () => {
	return (
		<div className={styles.registerSkeleton}>
			{[1, 2, 3, 4, 5, 6, 7].map((x, index) => (
				<span
					key={x}
					className={cn(`skeleton ${styles.skeletonInput}`)}
				/>
			))}
			<span className={cn(`skeleton ${styles.skeletonText}`)} />
			<span className={cn(`skeleton ${styles.skeletonButton}`)} />
		</div>
	);
};

export default RegisterSkeleton;
