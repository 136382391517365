import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./PersonalInfo.module.sass";
import TextInput from "themeComponents/TextInput";
import Loader from "themeComponents/Loader";
import ChangePassword from "./ChangePassword";
import Newsletter from "./Newsletter";

import { updateUserDetailsService, updatePassword } from "utils/authService";
import { saveUserData } from "store/authSlice";

const schema = yup.object().shape({
	firstName: yup.string().required("You must enter First name."),
	lastName: yup.string().required("You must enter Last name."),
	phone: yup.string().required("You must enter a Phone number."),
	email: yup.string().required("You must enter an Email Address."),
	companyName: yup.string().required("You must mention a Company."),
	address1: yup.string().required("You must enter an Address."),
});

const PersonalInfo = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);

	const [processing, setProcessing] = useState(false);

	const methods = useForm({
		mode: "onChange",
		defaultValues: {
			firstName: "",
			lastName: "",
			phone: "",
			email: "",
			companyName: "",
			address1: "",
			address2: "",
		},
		resolver: yupResolver(schema),
	});
	const {
		reset,
		watch,
		control,
		formState: { errors, isValid, isDirty },
		getValues,
	} = methods;
	const form = watch();

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setProcessing(true);
		const allValues = getValues();

		try {
			const response = await updateUserDetailsService(user.id, allValues);
			const updatedUserDetails = { ...user, ...response.data.data };
			localStorage.setItem("user_data", JSON.stringify(updatedUserDetails));
			dispatch(saveUserData(updatedUserDetails));
			toast.success("Profile Details updated Successfully!");
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		} finally {
			setProcessing(false);
		}
	};

	useEffect(() => {
		if (user) {
			reset({
				firstName: user?.first_name,
				lastName: user?.last_name,
				phone: user?.phone_number ? user?.phone_number : "",
				email: user?.email,
				companyName: user?.company_name ? user?.company_name : "",
				address1: user?.street_line_1 ? user?.street_line_1 : "",
				address2: user?.street_line_2 ? user?.street_line_2 : "",
			});
		}
	}, [user]);

	return (
		<>
			<form
				className={styles.section}
				onSubmit={handleFormSubmit}
			>
				<div className={styles.head}>
					<div className={cn("h2", styles.title)}>Personal info</div>
				</div>
				<div className={styles.list}>
					<div className={styles.item}>
						<div className={styles.category}>Account info</div>
						<div className={styles.fieldset}>
							<div className={styles.row}>
								<div className={styles.col}>
									<Controller
										name="firstName"
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextInput
												{...field}
												className={styles.field}
												label="First Name"
												type="text"
												placeholder="Enter your First name"
												required
												error={error}
												errorMessage={error?.message}
											/>
										)}
									/>
								</div>
								<div className={styles.col}>
									<Controller
										name="lastName"
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextInput
												{...field}
												className={styles.field}
												label="Last name"
												type="text"
												placeholder="Enter your Last name"
												required
												error={error}
												errorMessage={error?.message}
											/>
										)}
									/>
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.col}>
									<Controller
										name="phone"
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextInput
												{...field}
												className={styles.field}
												label="Phone"
												type="tel"
												placeholder="Phone number"
												required
												error={error}
												errorMessage={error?.message}
											/>
										)}
									/>
								</div>
								<div className={styles.col}>
									<Controller
										name="email"
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextInput
												{...field}
												className={styles.field}
												label="email"
												type="email"
												placeholder="Email"
												disabled
												error={error}
												errorMessage={error?.message}
											/>
										)}
									/>
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.col}>
									<Controller
										name="address1"
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextInput
												{...field}
												className={styles.field}
												label="address 1"
												type="text"
												placeholder="Address"
												required
												error={error}
												errorMessage={error?.message}
											/>
										)}
									/>
								</div>
								<div className={styles.col}>
									<Controller
										name="address2"
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextInput
												{...field}
												className={styles.field}
												label="address 2"
												type="text"
												placeholder="Address"
												error={error}
												errorMessage={error?.message}
											/>
										)}
									/>
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.col}>
									<Controller
										name="companyName"
										control={control}
										render={({ field, fieldState: { error } }) => (
											<TextInput
												{...field}
												className={styles.field}
												label="company name"
												type="text"
												placeholder="Company Name"
												required
												error={error}
												errorMessage={error?.message}
											/>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.controls}>
					<button
						className={cn("button", styles.button)}
						disabled={processing || !isValid || !isDirty}
						type="submit"
					>
						{processing ? <Loader color="white" /> : "Update profile"}
					</button>
				</div>
			</form>
			<hr />
			<ChangePassword />
			<hr />
			<Newsletter />
		</>
	);
};

export default PersonalInfo;
