import GoogleMap from "google-map-react";
import cn from "classnames";

import Icon from "components/Icon";

import styles from "./Map.module.sass";

const Map = ({ list,loading, pointers, defaultCenter = [38.04452474525394, -38.99925717550497], zoom = 0 }) => {
	const googleKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
	return (
        <>
                <section className={styles.mapsMainContainer}>
                    {!loading && pointers && pointers.length ? (
                        <GoogleMap
                            bootstrapURLKeys={{
                                key: googleKey,
                            }}
                            defaultZoom={zoom}
                            zoom={zoom}
                            defaultCenter={defaultCenter}
                        >
                            {pointers.map((pointer, index) => (
                                <div
                                    key={index}
                                    lat={pointer.latitude}
                                    lng={pointer.longitude}
                                    text="Location"
                                    className={styles.mapMarkerContainer}
                                >
                                    <Icon
                                        name="geo-pin"
                                        className={styles.locationMarker}
                                        style={{
                                            fontSize: 32,
                                        }}
                                    />
                                </div>
                            ))}
                        </GoogleMap>
                    ) : (
                        <div
                            className={cn(
                                `skeleton`,
                                styles.mapsSkeletonContainer
                            )}
                        >
                            {[1, 2, 3, 4, 5].map((x, index) => (
                                <span
                                    key={x}
                                    className={cn(
                                        `skeleton`,
                                        styles.mapsMarkerSkeleton
                                    )}
                                >
                                    <Icon
                                        name="geo-pin"
                                        className={styles.locationMarker}
                                        style={{
                                            fontSize: 32,
                                        }}
                                    />
                                </span>
                            ))}
                        </div>
                    )}
                </section>
        </>
    );
};

export default Map;
