import React, { useState, useEffect, useReducer } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
    useParams,
    useSearchParams,
    useNavigate,
    Link,
} from "react-router-dom";
import cn from "classnames";
import { toast } from "react-toastify";

import RegisterForm from "./RegisterForm";
import RegisterSkeleton from "./RegisterSkeleton";

import Icon from "themeComponents/Icon";
import Loader from "themeComponents/Loader";

import styles from "./Register.module.sass";

import { registerService, registerInvitationService } from "utils/authService";

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();

    const access_token = localStorage.getItem("jwt_access_token");

    const authState = useSelector((state) => state.auth.authState);

    const [loading, toggleLoadding] = useState(true);
    const [message, setMessage] = useState("");
    const [processing, toggleProcessing] = useState(false);
    const [tokenProcessingFailed, setTokenProcessingFailed] = useState(false);
    const [registerationProcessing, setRegisterationProcessing] =
        useState(false);
    const [newUser, setNewUser] = useState(null);

    const formSubmitHandler = async (formData) => {
        toggleProcessing(true);
        try {
            const registerResponse = await registerService(
                formData,
                searchParams.get("token"),
                searchParams.get("ref_code")
            );
            if (registerResponse.data.status === "Success") {
                navigate(
                    `/questionnaire?user_id=${registerResponse.data.data.id}`
                );
            } else if (registerResponse.data.status === "Failed") {
                setMessage(registerResponse.data.message);
            }
        } catch (err) {
            console.log("err", err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            toggleProcessing(false);
        }
    };

    const handleDataFetchingOnMount = async () => {
        toggleLoadding(true);
        try {
            const response = await registerInvitationService(
                searchParams.get("token"),
                searchParams.get("ref_code")
            );
            setNewUser(response.data.data);
        } catch (err) {
            console.log(err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
            if (err.response.data.status === "Failed") {
                setMessage(err.response.data.message);
                setTokenProcessingFailed(true);
                navigate("/");
                return;
            }
        } finally {
            toggleLoadding(false);
        }
    };

    useEffect(() => {
        if (authState && access_token) {
            navigate("/");
        }
        handleDataFetchingOnMount();
    }, []);

    let content = loading ? (
        <RegisterSkeleton />
    ) : (
        <RegisterForm
            onSubmit={formSubmitHandler}
            processing={processing}
            referralName={newUser.referral_name}
            newUser={newUser.invitee}
        />
    );

    if (registerationProcessing) {
        const status = message.includes("success");
        content = (
            <>
                <p className={styles.message}>
                    <Icon
                        name={status ? "success" : "warning"}
                        className={cn(styles.icon, {
                            [styles.success]: status,
                            [styles.warning]: !status,
                        })}
                    />
                    {message}
                </p>
                {message.includes("success") && (
                    <Link to="/">Redirect to Home</Link>
                )}
            </>
        );
    }

    if (tokenProcessingFailed && message.length) {
        content = (
            <p className={styles.message}>
                <Icon
                    name={"warning"}
                    className={`${styles.icon} ${styles.error}`}
                />
                {message}
            </p>
        );
    }

    const Stepper = () => {
        return (
            <div className={styles.stepperMain}>
                <div className={styles.stepperContainer}>
                    <div>
                        <p className={styles.first}>1</p>
                    </div>
                    <div>
                        <p className={styles.second}>2</p>
                    </div>
                </div>
                <div className={styles.line}></div>
            </div>
        );
    };

    return (
        <div className={cn('container', styles.container)}>
            <section className={styles.registerContainer}>
                <Link to='/'>
                    <h3 className={styles.headerTitle}>
                        Mother <br /> Club
                    </h3>
                </Link>
                <Stepper />

                <h3 className={cn('h3', styles.title)}>Registration</h3>
                {content}
            </section>
        </div>
    );
};

export default Register;
