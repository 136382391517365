import React, { useState } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Control from "../../../themeComponents/Control";
import Card from "components/Card";
import CardSkeleton from "components/CardSkeleton";

const Main = ({ list, loading }) => {
	return (
		<div className={cn("section", styles.section)}>
			<div className={cn("container", styles.container)}>
				<Control
					className={styles.control}
					urlHome="/"
					breadcrumbs={[]}
				/>
				<div className={styles.head}>
					<div className={styles.wrap}>
						<h1 className={cn("h2", styles.title)}>Bookings</h1> {loading}
						{!loading && (
							<div className={styles.counter}>
								You subscribed to {list.length} {list.length === 1 ? "event" : "events"}
							</div>
						)}
					</div>
				</div>

				<div className={styles.wrapper}>
					<div className={styles.list}>
						{loading
							? [1, 2, 3, 4, 5].map((x, index) => (
									<CardSkeleton
										key={x}
										className={styles.card}
									/>
							  ))
							: list.map((x, index) => (
									<Card
										className={styles.card}
										item={x}
										key={index}
									/>
							  ))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Main;
