import { useEffect } from "react";
import cn from "classnames";
import { toast } from "react-toastify";

import Modal from "components/Modal";

import styles from "./PaymentModal.module.sass";
import { useExternalScript } from "./../../hooks/paypalHook";

const PaymentModal = ({
    visibility,
    onClose,
    outerClassName,
    userId,
    price,
    onApprove,
    onCancel,
    referenceId,
    items,
    currency_code,
}) => {
    const paypalScript = useExternalScript(
        `https://www.paypal.com/sdk/js?client-id=AcB-69l2yxtpK_vPQtKlYiO3p2SsVvI07716HkAjB958QKoWLJzFkDerdJz8jTZDtiwJSFEuSlPgNFwz&currency=${currency_code}&vault=false`
    );

    useEffect(() => {
        if (window.paypal && visibility) {
            window.paypal
                .Buttons({
                    createOrder: function (data, actions) {
                        // Set up the transaction
                        return actions.order.create({
                            intent: "CAPTURE",
                            purchase_units: [
                                {
                                    reference_id: referenceId,
                                    amount: {
                                        currency_code: currency_code,
                                        value: price,
                                        breakdown: {
                                            item_total: {
                                                currency_code: currency_code,
                                                value: price,
                                            },
                                        },
                                    },
                                    items: items,
                                },
                            ],
                            application_context: {
                                brand_name: "Mother's Club",
                                locale: "en-US",
                                user_action: "PAY_NOW",
                                payment_method: {
                                    payer_selected: "PAYPAL",
                                    payee_preferred:
                                        "IMMEDIATE_PAYMENT_REQUIRED",
                                },
                            },
                        });
                    },
                    onApprove: function (data, actions) {
                        onApprove(data);
                    },
                    onCancel: function (data) {
                        onCancel(data);
                    },
                    onError: function (err) {
                        console.log(err);
                        toast.error("Error occured while processing payment.");
                    },
                })
                .render("#paypal-button-container"); // Renders the PayPal button
        }
    }, [visibility, paypalScript]);

    return (
        <Modal
            visible={visibility}
            onClose={onClose}
            outerClassName={cn(styles.paymentModalContainer, outerClassName)}
        >
            <>
                <h3>Subscription Payment</h3>
                <div id="paypal-button-container" />
            </>
        </Modal>
    );
};

export default PaymentModal;
