import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	publicArticlesList: [],
	articlesList: [],
};

const articlesSlice = createSlice({
	name: "Articles",
	initialState,
	reducers: {
		savePublicArticlesList: (state, action) => {
			state.publicArticlesList = action.payload;
		},
		saveArticlesList: (state, action) => {
			state.articlesList = action.payload;
		},
	},
});

export const { savePublicArticlesList, saveArticlesList } = articlesSlice.actions;

export default articlesSlice.reducer;
