import axios from "./axios";

export const fetchPublicArticles = async () => {
	try {
		const response = await axios.post("/events/core-articles/getAllArticle", {});
		return response;
	} catch (err) {
		throw err;
	}
};

export const fetchArticles = async () => {
	const access_token = localStorage.getItem("jwt_access_token");
	const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };
	try {
		const formData = new FormData();
		formData.append("posts", "private");
		const response = await axios.post("/events/core-articles/getAllArticle", formData, {
			headers: headers,
		});
		return response;
	} catch (err) {
		throw err;
	}
};
