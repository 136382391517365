import React, { useState, useRef } from "react";
import cn from "classnames";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import styles from "./InviteOthers.module.sass";
import TextInput from "themeComponents/TextInput";
import Loader from "themeComponents/Loader";
import Icon from "components/Icon";

import { setInviteByEmailBulk } from "utils/authService";
import axios from "axios";

const usersSchema = {
    first_name: yup.string().required("You must enter First name."),
    last_name: yup.string().required("You must enter Last name."),
    email: yup
        .string()
        .email("Enter correct email.")
        .required("You must enter an Email."),
};

const schema = yup.object().shape({
    users: yup
        .array()
        .of(yup.object().shape(usersSchema))
        .required("Must add all Users info."),
});

const InviteOthers = () => {
    const [processing, setProcessing] = useState(false);

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            users: [
                {
                    first_name: "",
                    last_name: "",
                    email: "",
                },
            ],
        },
        resolver: yupResolver(schema),
    });

    const {
        reset,
        watch,
        control,
        formState: { isValid, isDirty },
        getValues,
    } = methods;

    const form = watch();

    const { fields, append, remove } = useFieldArray({
        name: "users",
        control,
    });

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const allValues = getValues();

        setProcessing(true);

        try {
            const response = await setInviteByEmailBulk(
                JSON.stringify(allValues.users)
            );
            if (response.data.status === "Success") {
                toast.success(response.data.message);
                reset();
            } else {
                toast.warning(response.data.message);
            }
        } catch (err) {
            console.log("err", err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            setProcessing(false);
        }
    };

    return (
        <form className={styles.section} onSubmit={handleFormSubmit}>
            <div className={styles.titleHeader}>
                <h2 className={cn("h2", styles.title)}>Invite Others</h2>
                <button
                    type="button"
                    className={styles.addButton}
                    disabled={fields.length > 4}
                    onClick={() => append()}
                >
                    Add More User
                </button>
            </div>

            <div className={styles.list}>
                <div className={cn(styles.fieldset)}>
                    {fields.map((fieldItem, index) => (
                        <div key={index} className={styles.row}>
                            <div className={styles.fieldCol}>
                                <Controller
                                    name={`users.${index}.first_name`}
                                    control={control}
                                    render={({
                                        field,
                                        fieldState: { error },
                                    }) => (
                                        <TextInput
                                            {...field}
                                            className={styles.field}
                                            label="First Name"
                                            type="text"
                                            placeholder="Enter first name"
                                            required
                                            error={error}
                                            errorMessage={error?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.fieldCol}>
                                <Controller
                                    name={`users.${index}.last_name`}
                                    control={control}
                                    render={({
                                        field,
                                        fieldState: { error },
                                    }) => (
                                        <TextInput
                                            {...field}
                                            className={styles.field}
                                            label="Last Name"
                                            type="text"
                                            placeholder="Enter last name"
                                            required
                                            error={error}
                                            errorMessage={error?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div className={styles.fieldCol}>
                                <Controller
                                    name={`users.${index}.email`}
                                    control={control}
                                    render={({
                                        field,
                                        fieldState: { error },
                                    }) => (
                                        <TextInput
                                            {...field}
                                            className={styles.field}
                                            label="Email"
                                            type="text"
                                            placeholder="Enter email address"
                                            required
                                            error={error}
                                            errorMessage={error?.message}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className={styles.removeButton}
                                    onClick={() => remove(index)}
                                    disabled={index <= 0}
                                >
                                    <Icon name="close-circle" size="28" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.controls}>
                <button
                    className={cn("button", styles.button)}
                    disabled={processing || !isValid || !isDirty}
                    type="submit"
                >
                    {processing ? <Loader color="white" /> : "Send Invite"}
                </button>
            </div>
        </form>
    );
};

export default InviteOthers;
