import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./changePassword.module.sass";
import ResetPasswordForm from "./resetPasswordForm";
import { checkTokenExpiry } from "utils/authService";
import { toast } from "react-toastify";

const ResetChangePassword = () => {
    const params = useParams();
    const [passwordChange, setPasswordChange] = useState(false);
    const [tokenExpire, setTokenExpire] = useState(true);

    useEffect(() => {
        if (params?.token) {
            tokenExpiry(params?.token);
        }
        console.log(params);
    }, []);

    const tokenExpiry = async (token) => {
        const res = await checkTokenExpiry(token);
        if (res.data.status === "Success") {
            setTokenExpire(false);
        } else {
            toast.error("Email Link is expired!");
            // navigate("/");
        }
    };

    return (
        <div className={cn("container", styles.container)}>
            <section className={styles.registerContainer}>
                <h1>
                    <Link
                        to="/"
                        className={cn(
                            "hero",
                            styles.title,
                            styles.capitalHeading
                        )}
                    >
                        Mother Club
                    </Link>
                </h1>

                {!tokenExpire && (
                    <h3 className={cn("h3", styles.title)}>Reset Password</h3>
                )}

                {tokenExpire && (
                    <h3 className={cn("h3", styles.title)}>Link Expired</h3>
                )}

                {passwordChange || tokenExpire ? (
                    <>
                        <Link to="/">
                            <button className={cn("button", styles.button)}>
                                Go Back to Login
                            </button>
                        </Link>{" "}
                    </>
                ) : (
                    <ResetPasswordForm
                        setPasswordChange={setPasswordChange}
                        tokenExpire={tokenExpire}
                        setTokenExpire={setTokenExpire}
                    />
                )}
            </section>
        </div>
    );
};

export default ResetChangePassword;
