import React, { useState } from "react";
import cn from "classnames";
import styles from "./Favorite.module.sass";
import Icon from "../Icon";

const Favorite = ({ className, onClick, visible }) => {
	return (
		<button
			className={cn(
				"button-circle-stroke button-small",
				styles.button,
				{
					[styles.active]: visible,
				},
				className
			)}
			onClick={onClick}
		>
			<Icon
				name="heart"
				size="24"
			/>
			<Icon
				name="heart-fill"
				size="24"
			/>
		</button>
	);
};

export default Favorite;
