import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import Geocode from "react-geocode";

import styles from "./Ceremonies.module.sass";
import Card from "components/Card";
import CardSkeleton from "components/CardSkeleton";
import Map from "components/Map";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
Geocode.enableDebug();

const Ceremonies = ({ list = [], loading }) => {
    const ceremoniesList = useSelector(
        (state) => state.ceremonies.ceremoniesList
    );

    let sortedArray = [];

    try {
        sortedArray = [...list].sort((a, b) => {
            if (a.event_Name.trim() < b.event_Name.trim()) {
                return -1;
            }
            if (a.event_Name.trim() > b.event_Name.trim()) {
                return 1;
            }
            return 0;
        });

        console.log("apple: ", sortedArray);
    } catch (err) {
        console.log(err, "mango");
    }

    const [pointers, setPointers] = useState(null);
    const [loadingMap, setLoadingMap] = useState(true);

    const extractAllPointers = async () => {
        const locationForAllPointer = [];
        setLoadingMap(true);

        try {
            for (const x of ceremoniesList) {
                const newAddress = `${x?.Address} ${x.state_Name}, ${x.country_Name}`;
                const response = await Geocode.fromAddress(newAddress);
                const { lat, lng } = response.results[0].geometry.location;
                locationForAllPointer.push({
                    latitude: lat,
                    longitude: lng,
                });
            }

            setPointers([...locationForAllPointer]);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingMap(false);
        }
    };

    useEffect(() => {
        if (ceremoniesList.length) {
            extractAllPointers();
        }
    }, [ceremoniesList]);

    return (
        <div id="ceremonies" className={cn("section", styles.section)}>
            <div className={cn("container", styles.container)}>
                <div className={styles.head}>
                    <div className={styles.wrap}>
                        <h2 className={cn("h2", styles.title)}>Ceremonies</h2>
                    </div>
                </div>

                <div className={styles.wrapper}>
                    <div className={styles.list}>
                        {loading &&
                            [1, 2, 3, 4, 5].map((x, index) => (
                                <CardSkeleton key={x} className={styles.card} />
                            ))}

                        {list.length ? (
                            sortedArray.map((x, index) => (
                                <Card
                                    className={styles.card}
                                    item={x}
                                    key={index}
                                    showLikeBtn
                                />
                            ))
                        ) : (
                            <p className={styles.ceremonyNotFound}>
                                {" "}
                                No Ceremonies found!{" "}
                            </p>
                        )}
                    </div>
                </div>

                <Map loading={loadingMap} list={list} pointers={pointers} />

                <span id="startArticles"></span>
            </div>
        </div>
    );
};

export default Ceremonies;
