import Questionnaire from "../accountSettings/Questionnaire";
import React, { useState } from "react";

import styles from "../register/Register.module.sass";
import Icon from "components/Icon";
import classNames from "classnames";

import QuestionnaireStyle from "./Questionnaire.module.sass";

import { Link, useSearchParams } from "react-router-dom";

export default function QuestionnairePage() {
    const [showSuccess, setShowSuccess] = useState(false);

    const handleSuccess = () => {
        setShowSuccess(true);
    };

    const [searchParams] = useSearchParams();
    const user_id = searchParams.get("user_id");

    const successBlock = (
        <div className={styles.registrationSuccessContainer}>
            <p className={styles.message}>
                <Icon
                    name={"success"}
                    className={classNames(styles.icon, styles.success)}
                />
                We are deeply appreciative of your membership request.
                <br />
                Our team will check your application and will get back to you
                shortly.
            </p>

            <p className={styles.redirectLink}>
                <Link to="/">Redirect to Home</Link>
            </p>
        </div>
    );

    const Stepper = () => {
        return (
            <div className={QuestionnaireStyle.Q_stepperMain}>
                <div className={QuestionnaireStyle.Q_stepperContainer}>
                    <div>
                        <p className={QuestionnaireStyle.first}>1</p>
                    </div>
                    <div>
                        <p className={QuestionnaireStyle.second}>2</p>
                    </div>
                </div>
                <div className={QuestionnaireStyle.line}></div>
            </div>
        );
    };

    return (
        <div className="container">
            {showSuccess ? (
                successBlock
            ) : (
                <div className={QuestionnaireStyle.questionnaire_container}>
                    <h3 className={QuestionnaireStyle.bigText}>
                        <Link to="/">
                            Mother <br /> Club
                        </Link>
                    </h3>
                    <Stepper />
                    <Questionnaire
                        userID={user_id}
                        onSuccess={handleSuccess}
                        showSubTitle
                    />
                </div>
            )}
        </div>
    );
}
