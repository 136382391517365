import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import Icon from "themeComponents/Icon";
import Dropdown from "themeComponents/Dropdown";
import PersonalInfo from "./PersonalInfo";
import PaymentsMethod from "./PaymentsMethod";
import Questionnaire from "./Questionnaire";
import InviteOthers from "./InviteOthers";

import styles from "./AccountSettings.module.sass";

const items = [
	{
		title: "Personal info",
		icon: "user",
		protected: false,
	},
	{
		title: "Questionnaire",
		icon: "clipboard",
		protected: false,
	},
	{
		title: "Invite Others",
		icon: "envelope-paper",
		protected: false,
	},
	{
		title: "Payments",
		icon: "credit-card",
		protected: false,
	},
];

const AccountSettings = () => {
	const options = [];
	items.map((x) => options.push(x.title));

	const membershipConfiguration = useSelector((state) => state?.auth?.membershipConfiguration);

	const [activeTab, setActiveTab] = useState(options[0]);

	const handleClick = (x) => {
		setActiveTab(x.title);
	};

	useEffect(() => {
		if (membershipConfiguration && !membershipConfiguration.is_membership_subscribed) {
			setActiveTab(options[3]);
		}
	}, []);

	return (
		<div className={cn("section", styles.section)}>
			<div className={cn("container", styles.container)}>
				<Dropdown
					className={cn("tablet-show", styles.dropdown)}
					options={options}
					value={activeTab}
					setValue={setActiveTab}
				/>
				<div className={styles.menu}>
					{items.map((x, index) => (
						<button
							className={cn(styles.link, {
								[styles.active]: x.title === activeTab,
							})}
							onClick={() => handleClick(x, index)}
							key={index}
						>
							<Icon
								name={x.icon}
								size="16"
							/>
							{x.title}
						</button>
					))}
				</div>
				<div className={styles.wrapper}>
					{activeTab === options[0] && <PersonalInfo />}
					{activeTab === options[1] && <Questionnaire />}
					{activeTab === options[2] && <InviteOthers />}
					{activeTab === options[3] && <PaymentsMethod />}
				</div>
			</div>
		</div>
	);
};

export default AccountSettings;
