import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Main from "./Main";

import { saveSubscribedCeremoniesList } from "store/ceremoniesSlice";
import { fetchSubscribedCeremonies } from "utils/ceremoniesService";

const Bookings = () => {
	const dispatch = useDispatch();

	const subscribedCeremoniesList = useSelector((state) => state.ceremonies.subscribedCeremoniesList);

	const [filteredList, setFilteredList] = useState([]);
	const [loading, setLoading] = useState(true);

	const handleFetchDataOnMount = async () => {
		setLoading(true);
		try {
			const response = await fetchSubscribedCeremonies();
			dispatch(saveSubscribedCeremoniesList(response.data.data));
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const contentList = [];
		for (const item of subscribedCeremoniesList) {
			contentList.push({
				...item,
				categoryText: item.is_retreat ? "Retreat" : "Ceremony",
				event_Name: item.event_name,
				Address: item.address,
			});
		}
		setFilteredList(contentList);
	}, [subscribedCeremoniesList]);

	useEffect(() => {
		handleFetchDataOnMount();
	}, []);

	return (
		<Main
			list={filteredList}
			loading={loading}
		/>
	);
};

export default Bookings;
