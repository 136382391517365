import { Link } from "react-router-dom";
import cn from "classnames";

import styles from "./Error404.module.sass";

const Error404 = () => {
	return (
		<section className={cn("container", styles.container)}>
			<h1 className={cn("hero", styles.title)}>Mother Club</h1>
			<h3 className={styles.errorTitle}>404</h3>
			<h4 className={styles.errorHeading}>We looked everywhere but didn't find the resource you requested.</h4>
			<Link
				className={styles.homeLink}
				to="/"
			>
				Go To Homepage
			</Link>
		</section>
	);
};
export default Error404;
