import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import TextInput from "themeComponents/TextInput";
import Loader from "themeComponents/Loader";

import { updatePassword } from "utils/authService";

import styles from "./PersonalInfo.module.sass";

const passwordShapeErr = "Password must be atleast 8 characters long. And should include one capital letter, one special character and one number.";

const schema = yup.object().shape({
	oldPassword: yup
		.string()
		.required("You must enter Old Password.")
		.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, passwordShapeErr),
	newPassword: yup
		.string()
		.required("You must enter New Password.")
		.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, passwordShapeErr)
		.notOneOf([yup.ref("oldPassword")], "New Password can't be same as old password."),
	confirmPassword: yup
		.string()
		.required("You must Confirm New Password.")
		.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, passwordShapeErr)
		.oneOf([yup.ref("newPassword")], "New password must match"),
});

const ChangePassword = () => {
	const user = useSelector((state) => state.auth.user);

	const [processing, setProcessing] = useState(false);

	const methods = useForm({
		mode: "onTouched",
		defaultValues: {
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
		},
		resolver: yupResolver(schema),
	});
	const {
		reset,
		watch,
		control,
		formState: { errors, isValid, isDirty },
		getValues,
		register,
		setValue,
	} = methods;
	const form = watch();

	const handleChangePassword = async (e) => {
		e.preventDefault();
		setProcessing(true);

		const { oldPassword, confirmPassword, newPassword } = getValues();

		try {
			const response = await updatePassword(user.id, newPassword, confirmPassword, oldPassword);
			toast.success("Password changed Successfully!");
			reset();
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		} finally {
			setProcessing(false);
		}
	};

	return (
		<form
			className={styles.section}
			onSubmit={handleChangePassword}
		>
			<div className={styles.head}>
				<div className={cn("h2", styles.title)}>Change Password</div>
			</div>
			<div className={styles.list}>
				<div className={styles.item}>
					<div className={styles.fieldset}>
						<div className={styles.row}>
							<div className={styles.col}>
								<Controller
									name="oldPassword"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextInput
											{...field}
											className={styles.field}
											label="Old Password"
											type="text"
											placeholder="Enter Old Password"
											required
											error={error}
											errorMessage={error?.message}
										/>
									)}
								/>
							</div>
						</div>

						<div className={styles.row}>
							<div className={styles.col}>
								<Controller
									name="newPassword"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextInput
											{...field}
											className={styles.field}
											label="New Password"
											type="text"
											placeholder="Enter New Password"
											required
											error={error}
											errorMessage={error?.message}
										/>
									)}
								/>
							</div>
							<div className={styles.col}>
								<Controller
									name="confirmPassword"
									control={control}
									render={({ field, fieldState: { error } }) => (
										<TextInput
											{...field}
											className={styles.field}
											label="Confirm New Password"
											type="text"
											placeholder="Confirm New Password"
											required
											error={error}
											errorMessage={error?.message}
										/>
									)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.controls}>
				<button
					className={cn("button", styles.button)}
					disabled={processing || !isValid || !isDirty}
					type="submit"
				>
					{processing ? <Loader color="white" /> : "Update password"}
				</button>
			</div>
		</form>
	);
};

export default ChangePassword;
