import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Main from "./Main";

import { saveFavoriteCeremoniesList } from "store/ceremoniesSlice";
import { fetchFavoriteCeremonies } from "utils/ceremoniesService";

const Wishlists = () => {
	const dispatch = useDispatch();

	const favoriteCeremoniesList = useSelector((state) => state.ceremonies.favoriteCeremoniesList);

	const [filteredList, setFilteredList] = useState([]);
	const [loading, setLoading] = useState(true);

	const handleFetchDataOnMount = async () => {
		setLoading(true);
		try {
			const response = await fetchFavoriteCeremonies();
			dispatch(saveFavoriteCeremoniesList(response.data.data));
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const contentList = [];
		for (const item of favoriteCeremoniesList) {
			contentList.push({
				...item,
				categoryText: item.is_retreat ? "Retreat" : "Ceremony",
				event_Name: item.event_name,
				Address: item.address,
			});
		}
		setFilteredList(contentList);
	}, [favoriteCeremoniesList]);

	useEffect(() => {
		handleFetchDataOnMount();
	}, []);

	return (
		<>
			<Main
				list={filteredList}
				loading={loading}
			/>
		</>
	);
};

export default Wishlists;
