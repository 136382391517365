import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";

import styles from "./Modal.module.sass";
import Icon from "components/Icon";

const Modal = ({ outerClassName, visible, onClose, children }) => {
	const escFunction = useCallback(
		(e) => {
			if (e.keyCode === 27) {
				onClose();
			}
		},
		[onClose]
	);

	useEffect(() => {
		document.addEventListener("keydown", escFunction, false);
		return () => {
			document.removeEventListener("keydown", escFunction, false);
		};
	}, [escFunction]);

	useEffect(() => {
		if (visible) {
			const target = document.querySelector("#modal");
			disableBodyScroll(target);
		} else {
			clearAllBodyScrollLocks();
		}
	}, [visible]);

	return createPortal(
		visible && (
			<div
				className={styles.modal}
				id="modal"
			>
				<div className={cn(styles.outer, outerClassName)}>
					<OutsideClickHandler onOutsideClick={()=>{}}>
						{children}
						<button
							className={styles.close}
							onClick={onClose}
						>
							<Icon
								name="close"
								size="24"
							/>
						</button>
					</OutsideClickHandler>
				</div>
			</div>
		),
		document.body
	);
};

export default Modal;
