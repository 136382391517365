/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import Loader from "themeComponents/Loader";
import TextInput from "themeComponents/TextInput";
import Checkbox from "themeComponents/Checkbox";

import {
    fetchQuestionnaireTypes,
    fetchQuestionsByID,
    saveAnswers,
} from "utils/questionnaireService";

import styles from "./Questionnaire.module.sass";
import RadioStyles from "components/Radio/Radio.module.sass";

const questionSchema = {
    // answer: yup.string().typeError("Enter correct format"),
};
const questions = {
    // questions: yup.array().of(yup.object().shape(questionSchema)),
};

const schema = yup.object().shape({
    // questionnaire: yup.array().of(yup.object().shape(questions)),
});

const Questionnaire = ({ showSubTitle, onSuccess, userID }) => {
    const [questionnaireList, setQuestionnaireList] = useState([]);
    const [sortedQuestionnaireList, setSortedQuestionnaireList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [savingAnswers, setSavingAnswers] = useState(false);

    const methods = useForm({
        mode: "onChange",
        defaultValues: {
            questionnaire: [
                {
                    questionnaireTitle: "",
                    questions: [
                        {
                            questionId: "",
                            question: "",
                            question_option_id: "",
                            options_name: [],
                            question_option_choice_ids: [],
                            answer: "",
                        },
                    ],
                },
            ],
        },
        resolver: yupResolver(schema),
    });
    const {
        reset,
        watch,
        control,
        formState: { errors, isDirty },
        getValues,
        register,
        setValue,
    } = methods;
    const form = watch();
    const { fields, append, remove } = useFieldArray({
        name: "questionnaire",
        control,
    });

    const handleFetchDataOnMount = async () => {
        setLoading(true);
        try {
            const response = await fetchQuestionnaireTypes(userID);
            setQuestionnaireList(response.data.data);
        } catch (err) {
            console.log("err", err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSaveAnwers = async () => {
        const allValues = getValues();
        console.log("va", allValues);

        let isValid = true;

        allValues.questionnaire?.map((m) => {
            m.questions.map((q) => {
                if (q.is_mandatory) {
                    if (q.question_option_code === "multiple_choice") {
                        if (
                            !q.question_option_choice_ids.some((s) => s.value)
                        ) {
                            isValid = false;
                        }
                    } else if (q.question_option_code === "text") {
                        if (!q.answer) {
                            isValid = false;
                        }
                    } else if (q.question_option_code === "input") {
                        if (!q.answer) {
                            isValid = false;
                        }
                    } else if (q.question_option_code === "true_false") {
                        if (q.answer === "false") {
                            isValid = false;
                        }
                    }
                }
            });
        });
        if (!isValid) {
            toast.error(
                "All the required questions are needed to be answered!"
            );
            return false;
        }
        setSavingAnswers(true);
        try {
            let answersList = [];
            allValues.questionnaire.map((questionType) => {
                questionType.questions.map((question) => {
                    if (question?.question_option_code === "multiple_choice") {
                        let filteredAnswers =
                            question?.question_option_choice_ids.filter(
                                (item) => item.value
                            );
                        let answers = filteredAnswers?.map(
                            (option) => option.id
                        );

                        console.log({ filteredAnswers, answers });

                        answersList.push({
                            question_id: question.questionId,
                            answer: answers,
                        });
                    } else if (
                        question?.question_option_code === "true_false"
                    ) {
                        answersList.push({
                            question_id: question.questionId,
                            answer: question.answer === "true" ? true : false,
                        });
                    } else {
                        answersList.push({
                            question_id: question.questionId,
                            answer: question.answer,
                        });
                    }
                });
            });
            const response = await saveAnswers(answersList, userID);
            if (response.status === 200) {
                toast.success("Submitted Successfully");
                if (onSuccess) {
                    onSuccess();
                }
            }
            reset(allValues);
        } catch (err) {
            console.log("err", err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            setSavingAnswers(false);
        }
    };

    const shouldOptionChecked = (selectedOptions = [], id = "") => {
        console.log({ id, selectedOptions });
        return selectedOptions?.includes(id.toString());
    };

    useEffect(() => {
        if (sortedQuestionnaireList.length) {
            let list = sortedQuestionnaireList.map((q, index) => {
                const arr = q.questions_answers.map((item) => {
                    return {
                        questionId: item.id,
                        question: item.question,
                        is_mandatory: item.is_mandatory,
                        question_option_code:
                            item?.question_option?.question_option_code,
                        options_name: item?.question_option?.options_name,
                        question_option_choice_ids:
                            item?.question_option?.options_name?.map(
                                (option) => {
                                    return {
                                        id: option.question_option_choice_id,
                                        value: shouldOptionChecked(
                                            item?.userAnswer
                                                ?.question_option_choice_ids,
                                            option.question_option_choice_id
                                        ),
                                    };
                                }
                            ),

                        answer:
                            item?.userAnswer && item?.userAnswer?.answer
                                ? item?.userAnswer?.answer
                                : "",
                    };
                });

                console.log("arrrrrrrrr: ", arr);
                return {
                    questionnaireTitle: q.question_type_Data.name,
                    questions: arr,
                };
            });

            // console.log({ list });
            reset({
                questionnaire: list,
            });
        }
    }, [sortedQuestionnaireList]);

    useEffect(() => {
        if (questionnaireList.length) {
            const sortedQuestionnaireList = [...questionnaireList].sort(
                (a, b) => parseFloat(a.sort_number) - parseFloat(b.sort_number)
            );

            const list = sortedQuestionnaireList.map((q, index) => {
                const questionsAnswers = q.questions_answers;
                const sortedQuestionsAnswersArr = [...questionsAnswers].sort(
                    (a, b) =>
                        parseFloat(a.sort_number) - parseFloat(b.sort_number)
                );
                q.questions_answers = sortedQuestionsAnswersArr;
                return q;
            });

            setSortedQuestionnaireList(list);
        }
    }, [questionnaireList]);

    useEffect(() => {
        handleFetchDataOnMount();
    }, []);

    return (
        <div className={styles.section}>
            {showSubTitle ? (
                <h3 className={styles.bigText}>Questionnaire</h3>
            ) : (
                <h2 className={cn("h2", styles.title)}>Questionnaire</h2>
            )}

            {showSubTitle && (
                <>
                    <h3 className={styles.subTitles}>
                        The Mother Club extends its warmest welcome.
                    </h3>
                    <h3 className={styles.subTitles}>
                        To submit your membership application, please complete
                        the questionnaire and provide payment information.
                    </h3>
                </>
            )}

            {loading ? (
                <section className={styles.skeletonContainer}>
                    {/* TODO: Put skeleton loading here */}
                    <Loader color="white" />
                </section>
            ) : (
                <>
                    {fields.map((field, index) => (
                        <section
                            key={field.id}
                            className={styles.questionnaireContainer}
                        >
                            <h3 className={styles.questionnaireHeading}>
                                {field.questionnaireTitle}
                            </h3>
                            <section className={styles.questionsContainer}>
                                {field.questions.map((question, idx) => (
                                    <section
                                        className={styles.questionContainer}
                                    >
                                        <p className={styles.questionText}>
                                            {question.question}{" "}
                                            {question?.is_mandatory && (
                                                <span
                                                    className={
                                                        styles.labelRequired
                                                    }
                                                >
                                                    *
                                                </span>
                                            )}
                                        </p>

                                        {question.question_option_code ===
                                            "multiple_choice" &&
                                            question.options_name?.map(
                                                (option, i) => (
                                                    <Controller
                                                        name={`questionnaire.${index}.questions.${idx}.question_option_choice_ids.${i}.value`}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <div
                                                                className={
                                                                    styles.checkbox_container
                                                                }
                                                            >
                                                                <Checkbox
                                                                    className="mt-8 mb-16 mr-2"
                                                                    {...field}
                                                                    checked={
                                                                        field.value
                                                                    }
                                                                />
                                                                <span
                                                                    htmlFor=""
                                                                    className={
                                                                        styles.questionLabel
                                                                    }
                                                                >
                                                                    {
                                                                        option.question_option_choice_name
                                                                    }
                                                                </span>
                                                            </div>
                                                        )}
                                                    />
                                                )
                                            )}

                                        {question.question_option_code ===
                                            "true_false" && (
                                            <div
                                                className={
                                                    styles.checkbox_container
                                                }
                                            >
                                                <label
                                                    className={cn(
                                                        RadioStyles.radio
                                                    )}
                                                >
                                                    <input
                                                        {...register(
                                                            `questionnaire.${index}.questions.${idx}.answer`
                                                        )}
                                                        className={
                                                            RadioStyles.input
                                                        }
                                                        type="radio"
                                                        name={`questionnaire.${index}.questions.${idx}.answer`}
                                                        value="true"
                                                        required={
                                                            question?.is_mandatory
                                                        }
                                                    />
                                                    <span
                                                        className={
                                                            RadioStyles.inner
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                RadioStyles.tick
                                                            }
                                                        ></span>
                                                        <span
                                                            className={
                                                                RadioStyles.text
                                                            }
                                                            style={{
                                                                marginRight:
                                                                    "10px",
                                                            }}
                                                        >
                                                            True
                                                        </span>
                                                    </span>
                                                </label>
                                                <label
                                                    className={
                                                        RadioStyles.radio
                                                    }
                                                >
                                                    <input
                                                        {...register(
                                                            `questionnaire.${index}.questions.${idx}.answer`
                                                        )}
                                                        className={
                                                            RadioStyles.input
                                                        }
                                                        type="radio"
                                                        name={`questionnaire.${index}.questions.${idx}.answer`}
                                                        value="false"
                                                    />
                                                    <span
                                                        className={
                                                            RadioStyles.inner
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                RadioStyles.tick
                                                            }
                                                        ></span>
                                                        <span
                                                            className={
                                                                RadioStyles.text
                                                            }
                                                        >
                                                            False
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                        )}

                                        {question.question_option_code ===
                                            "text" && (
                                            <Controller
                                                name={`questionnaire.${index}.questions.${idx}.answer`}
                                                control={control}
                                                render={({
                                                    field,
                                                    fieldState: { error },
                                                }) => (
                                                    <>
                                                        <TextInput
                                                            {...field}
                                                            className={
                                                                styles.field
                                                            }
                                                            type="text"
                                                            placeholder="Enter your Answer here."
                                                            // required={
                                                            //     question?.is_mandatory
                                                            // }
                                                            textArea
                                                            error={error}
                                                            errorMessage={
                                                                error?.message
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                        )}
                                        {question.question_option_code ===
                                            "input" && (
                                            <Controller
                                                name={`questionnaire.${index}.questions.${idx}.answer`}
                                                control={control}
                                                render={({
                                                    field,
                                                    fieldState: { error },
                                                }) => (
                                                    <>
                                                        <TextInput
                                                            {...field}
                                                            className={
                                                                styles.field
                                                            }
                                                            type="text"
                                                            placeholder="Enter your Answer here."
                                                            // required={
                                                            //     question?.is_mandatory
                                                            // }
                                                            error={error}
                                                            errorMessage={
                                                                error?.message
                                                            }
                                                        />
                                                    </>
                                                )}
                                            />
                                        )}
                                    </section>
                                ))}
                            </section>
                        </section>
                    ))}

                    {showSubTitle ? (
                        <button
                            className={cn("button", styles.button_2)}
                            onClick={handleSaveAnwers}
                            // disabled={!isValid || !isDirty || savingAnswers}
                        >
                            {savingAnswers ? (
                                <Loader color="white" />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    ) : (
                        <button
                            className={cn("button", styles.button)}
                            onClick={handleSaveAnwers}
                            // disabled={!isValid || !isDirty || savingAnswers}
                        >
                            {savingAnswers ? (
                                <Loader color="white" />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default Questionnaire;
