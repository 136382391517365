import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import styles from "./ResetPassword.module.sass";
import TextInput from "themeComponents/TextInput";
import Loader from "themeComponents/Loader";
import { resetPasswordService } from "utils/authService";
import {
    saveUserData,
    toggleAuthState,
    saveUserMembershipConfiguration,
} from "store/authSlice";

const schema = yup.object().shape({
    email: yup
        .string()
        .email("Enter correct Email Address.")
        .required("You must enter an Email Address.")
        .typeError("Only correct Email format is allowed."),
});

const ResetPassword = ({ onClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [processing, toggleProcessing] = useState(false);

    const methods = useForm({
        mode: "onTouched",
        defaultValues: {
            email: "",
        },
        resolver: yupResolver(schema),
    });
    const {
        reset,
        watch,
        control,
        formState: { isValid, isDirty },
        getValues,
        setValue,
    } = methods;
    const form = watch();

    const loginFormSubmitHandler = async (e) => {
        e.preventDefault();
        const allValues = getValues();
        toggleProcessing(true);
        try {
            const response = await resetPasswordService(allValues.email);
            if(response.status === 200){
                toast.success('Successfully email sent with instructions!')
            }

            onClose()
        } catch (err) {
            console.log("err", err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            toggleProcessing(false);
        }
    };

    return (
        <div className={cn(styles.login)}>
            <div className={styles.item}>
                <div className={cn("h3", styles.title)}>Reset Password</div>

                <form className={styles.form} onSubmit={loginFormSubmitHandler}>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                {...field}
                                className={styles.field}
                                type="text"
                                placeholder="Email Address"
                                required
                                error={error}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <button
                        className={cn("button button-brown", styles.button)}
                        type="submit"
                        disabled={processing || !isValid || !isDirty}
                    >
                        {processing ? (
                            <Loader color="white" />
                        ) : (
                            "Send Email Instructions"
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
