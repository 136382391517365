import React from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";
import * as dayjs from "dayjs";

import styles from "./Card.module.sass";
import Icon from "components/Icon";
import Favorite from "themeComponents/Favorite";

import { toggleCeremonyFavoriteStatus } from "utils/ceremoniesService";
import { updateFavoriteStatusForCeremony } from "store/ceremoniesSlice";
import { toast } from "react-toastify";

const Item = ({ className, item, row, car, showLikeBtn = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleFavoriteStatusUpdate = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log({ item });
        try {
            const response = await toggleCeremonyFavoriteStatus(item.event_id);
            if (response.data.message.includes("Un-Favorite Event")) {
                dispatch(
                    updateFavoriteStatusForCeremony({
                        eventId: item.event_id,
                        status: false,
                    })
                );
            } else {
                dispatch(
                    updateFavoriteStatusForCeremony({
                        eventId: item.event_id,
                        status: true,
                    })
                );
            }
        } catch (err) {
            console.log("err", err);
        }
    };

    const handleMove = (e, item) => {
        e.preventDefault();
        if (item.is_ceremony_deleted) {
            toast.error("This ceremony doesn't exits");
        } else {
            navigate(`/ceremony/${item.event_id}`);
        }
    };

    return (
        <Link
            className={cn(
                className,
                styles.card,
                { [styles.row]: row },
                { [styles.car]: car }
            )}
            onClick={(e) => handleMove(e, item)}
            to={"#"}
            // to={item.is_ceremony_deleted ? '#' : `/ceremony/${item.event_id}`}
        >
            <div className={styles.preview}>
                <img
                    srcSet={`${item.event_img[0]} 2x`}
                    src={item.event_img[0]}
                    alt="Nature"
                />
                {item.categoryText && (
                    <div
                        className={cn(
                            "category",
                            { "category-blue": item.category === "blue" },
                            styles.category
                        )}
                    >
                        {item.categoryText}
                    </div>
                )}
                {showLikeBtn && (
                    <div className={styles.like}>
                        <Favorite
                            className={styles.favorite}
                            visible={item.is_favorite}
                            onClick={handleFavoriteStatusUpdate}
                        />
                    </div>
                )}
            </div>
            <div className={styles.body}>
                <div className={styles.line}>
                    <div className={styles.title}>{item.event_Name}</div>
                    <div className={styles.price}>
                        <div className={styles.actual}>
                            {item.ticket_price && item.ticket_price > 0
                                ? `${
                                      item.currency ? item.currency : "€"
                                  }${item.ticket_price.toLocaleString()}`
                                : "Free"}
                        </div>
                    </div>
                </div>
                <div className={styles.options}>
                    <div className={styles.option}>
                        <Icon name="geo-pin" size="16" />
                        <p
                            title={`${item.Address} ${item.state_Name}, ${item.country_Name}`}
                        >
                            {item.Address} {item.state_Name} ,{" "}
                            {item.country_Name}
                        </p>
                    </div>
                </div>
                <div className={styles.foot}>
                    {item.comment && (
                        <div className={styles.comment}>
                            <div className={styles.avatar}>
                                <img src={item.avatar} alt="Avatar" />
                            </div>
                            <div className={styles.text}>{item.comment}</div>
                        </div>
                    )}
                    <div className={styles.flex}>
                        <div className={styles.date}>
                            {/* {dayjs(item.start_event_date, "YYYY-MM-DD").format(
                                "MMM DD, YYYY"
                            )}{" "}
                            -{" "}
                            {dayjs(item.end_event_date).format("MMM DD, YYYY")} */}
                            {`${item.start_event_date} - ${item.end_event_date} `}
                        </div>
                        <div className={styles.tickets}>
                            {/* <Icon
								name="ticket"
								size="20"
							/> */}
                            <div className={styles.ticketStats}>
                                {/* <p className={styles.number}>
									{item.maximim_members - item.number_of_seats_left} Registered
								</p> */}
                                <p className={styles.number}>
                                    {item.number_of_seats_left < 1
                                        ? "Booked out"
                                        : `${item.number_of_seats_left}  ${
                                              item.number_of_seats_left > 1
                                                  ? " Places Available"
                                                  : "Place Available"
                                          }`}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default Item;
