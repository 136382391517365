import React, { useEffect, useState } from "react";
import { Outlet, useLocation, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import cn from "classnames";

import styles from "./ProtectedLayout.module.sass";
import Header from "components/Header";
import Footer from "components/Footer";
import Loader from "themeComponents/Loader";
import { saveUserData, toggleAuthState, saveUserMembershipConfiguration } from "store/authSlice";
import { getToken, logoutService } from "utils/authService";

const protectedRoutesLookup = ["/ceremony", "/retreat", "/bookings", "/wishlists", "/account-settings"];

const ProtectedBasicLayout = ({ separatorHeader, children, fooferHide, wide, notAuthorized }) => {
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const authState = useSelector((state) => state.auth.authState);

	const access_token = localStorage.getItem("jwt_access_token");

	const [loading, toggleLoading] = useState(true);

	const determineAuthStatus = async () => {
		const access_token = localStorage.getItem("jwt_access_token");
		const userJSON = localStorage.getItem("user_data");
		const membershipConfigurationJSON = localStorage.getItem("membership_configuration");

		if (access_token) {
			const user = JSON.parse(userJSON);
			const membershipConfiguration = JSON.parse(membershipConfigurationJSON);

			if (user && user.email) {
				try {
					const getTokenResponse = await getToken(access_token, user.email);
					console.log(getTokenResponse);

					// if (getTokenResponse.statusCode === 400){
					// 	console.log(getTokenResponse);
					// }
                        if (getTokenResponse.data.expire) {
                            await logoutService();
                            navigate("/");
                            toggleLoading(false);
                            return;
                        } 

						
				} catch (err) {
					if (err.response.data.message.includes("session has been expired")) {
						await logoutService();
                        navigate("/");
                        toggleLoading(false);
                        return;
					}
                    console.log(err, "tokenvalid");
				}
			}

			dispatch(toggleAuthState(true));
			dispatch(saveUserData(user));
			dispatch(saveUserMembershipConfiguration(membershipConfiguration));
			toggleLoading(false);
		} else {
			localStorage.removeItem("jwt_access_token");
			localStorage.removeItem("user_data");
			localStorage.removeItem("membership_configuration");
			toggleLoading(false);
		}
	};

	useEffect(() => {
		const membershipConfigurationJSON = localStorage.getItem("membership_configuration");
		const membershipConfiguration = JSON.parse(membershipConfigurationJSON);
		if (access_token && membershipConfiguration && !membershipConfiguration.is_membership_subscribed) {
			const includes =
				pathname.includes("/retreat") || pathname.includes("/ceremony") || pathname.includes("/bookings") || pathname.includes("/wishlists");

			if (includes) navigate("/");
		}

		if (!access_token) {
			const includes =
				pathname.includes("/retreat") ||
				pathname.includes("/ceremony") ||
				pathname.includes("/bookings") ||
				pathname.includes("/wishlists") ||
				pathname.includes("/account-settings");

			if (includes) navigate("/");
		}

		// console.log("pathname changed", pathname);

		window.scrollTo(0, 0);
		clearAllBodyScrollLocks();
	}, [pathname]);

	useEffect(() => {
		determineAuthStatus();
	}, []);

	return (
		<>
			{loading ? (
				<section className={styles.loadingContainer}>
					<h1 className={cn("hero", styles.title)}>Mother Club</h1>
					<Loader className={styles.loader} />
				</section>
			) : (
				<section className={styles.page}>
					{access_token && (
						<Header
							separatorHeader={separatorHeader}
							wide={wide}
							notAuthorized={notAuthorized}
						/>
					)}
					<div className={styles.inner}>
						<Outlet />
					</div>
					{!fooferHide && <Footer />}
				</section>
			)}
		</>
	);
};

export default ProtectedBasicLayout;
