import React from "react";
import cn from "classnames";
import styles from "./Banner.module.sass";

import homeLegend from "assets/home-bg.jpg";

const Banner = () => {
	return (
		<div className={cn(styles.section)}>
			<div className={cn("container", styles.container)}>
				<div className={styles.preview}>
					<picture>
						<source
							media="(max-width: 767px)"
							srcSet={homeLegend}
						/>
						<img
							src={homeLegend}
							alt="Main"
						/>
					</picture>
					<div className={styles.wrap}>
						<h1 className={cn("hero", styles.title)}>Mother Club</h1>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Banner;
