import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Main from "./Main";
import Banner from "./Banner";
import Navbar from "./Navbar";
import Ceremonies from "./Ceremonies";
import Retreat from "./Retreat";
import Articles from "./Articles";

import Modal from "components/Modal";
import Login from "components/Login";

import styles from "./Home.module.sass";

import { fetchCeremonies } from "utils/ceremoniesService";
import { saveCeremoniesList } from "store/ceremoniesSlice";

import { fetchArticles, fetchPublicArticles } from "utils/articlesService";
import { saveArticlesList, savePublicArticlesList } from "store/articlesSlice";

import { fetchRetreats } from "utils/retreatsService";
import { saveRetreatsList } from "store/retreatsSlice";
import ResetPassword from "components/ResetPassword";

const Home = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const authState = useSelector((state) => state.auth.authState);
	const ceremoniesList = useSelector((state) => state.ceremonies.ceremoniesList);
	const articlesList = useSelector((state) => state.articles.articlesList);
	const publicArticlesList = useSelector((state) => state.articles.publicArticlesList);
	const membershipConfiguration = useSelector((state) => state?.auth?.membershipConfiguration);
	const retreatsList = useSelector((state) => state.retreats.retreatsList);

	const [loading, toggleLoadding] = useState(true);
	const [receivedEmail, setReceivedEmail] = useState(null);

	const [resetPassword, setResetPassword] = useState(false);

	const [signInModalVisibility, setSignInModalVisibility] = useState(false);

	const handlePublicDataFetchingOnMount = async () => {
		toggleLoadding(true);
		try {
			const articlesResponse = await fetchPublicArticles();
			dispatch(savePublicArticlesList(articlesResponse.data.data));
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		} finally {
			toggleLoadding(false);
		}
	};

	const handlePrivateDataFetchingOnMount = async () => {
		toggleLoadding(true);
		try {
			const ceremoniesResponse = await fetchCeremonies();
			dispatch(saveCeremoniesList(ceremoniesResponse.data.data));

			const articlesResponse = await fetchArticles();
			dispatch(saveArticlesList(articlesResponse.data.data));

			const retreatsResponse = await fetchRetreats();
			dispatch(saveRetreatsList(retreatsResponse.data.data));
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		} finally {
			toggleLoadding(false);
		}
	};

	useEffect(() => {
		if (membershipConfiguration && membershipConfiguration.is_membership_subscribed) {
			handlePrivateDataFetchingOnMount();
		} else {
			handlePublicDataFetchingOnMount();
		}
		if (Object.keys(Object.fromEntries(searchParams)).length && searchParams.get("email").length) {
			setSignInModalVisibility(true);
			setReceivedEmail(searchParams.get("email"));
		}
	}, []);

	const check = () => authState && membershipConfiguration && membershipConfiguration.is_membership_subscribed;

	let ArticlesContent = check() && (
        <Articles
            loading={loading}
            list={check() ? articlesList : publicArticlesList}
            stickyAllowed={true}
        />
    );
	let RetreatContent = <Retreat loading={loading} retreat={retreatsList[retreatsList.length - 1]} />;

	if (!loading) {
		if ((check() && articlesList.length < 1) || (!check() && publicArticlesList.length < 1)) {
			ArticlesContent = null;
		}
		if (check() && retreatsList.length < 1) {
			RetreatContent = null;
		}
	}

	return (
        <>
            {check() && <Navbar />}
            {authState ? (
                <Banner />
            ) : (
                <Main onSignUp={() => setSignInModalVisibility(true)} />
            )}
            {check() && <Ceremonies loading={loading} list={ceremoniesList} />}
            {ArticlesContent}
            {check() && (
                <Retreat
                    loading={loading}
                    retreat={retreatsList[retreatsList.length - 1]}
                />
            )}
            {!authState && (
                <Modal
                    visible={signInModalVisibility}
                    onClose={() => {
                        setSignInModalVisibility(false);
                        setResetPassword(false);
                    }}
                >
                    {!resetPassword && (
                        <Login
                            receivedEmail={receivedEmail}
                            onClose={() => setSignInModalVisibility(false)}
                            setResetPassword={setResetPassword}
                        />
                    )}
                    {resetPassword && (
                        <ResetPassword
                            onClose={() => {
                                setSignInModalVisibility(false);
                                setResetPassword(false);
                            }}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};

export default Home;
