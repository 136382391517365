import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import * as dayjs from "dayjs";
import { toast } from "react-toastify";

import Modal from "components/Modal";
import TextInput from "themeComponents/TextInput";
import NotificationModal from "components/NotificationModal";
import Icon from "components/Icon";

import styles from "./PaymentsMethod.module.sass";

import { saveUserMembershipConfiguration } from "store/authSlice";
import {
    notifyOnMembershipPaymentApprove,
    notifyOnMembershipPaymentCancel,
} from "utils/paymentService";
import { useExternalScript } from "./../../../hooks/paypalHook";

const PaymentsMethod = () => {
    const dispatch = useDispatch();

    const membershipConfiguration = useSelector(
        (state) => state?.auth?.membershipConfiguration
    );

    const userDetails = useSelector((state) => state.auth.user);

    const [modalVisibility, setModalVisibility] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);

    const paypalScript = useExternalScript(
        `https://www.paypal.com/sdk/js?client-id=AcB-69l2yxtpK_vPQtKlYiO3p2SsVvI07716HkAjB958QKoWLJzFkDerdJz8jTZDtiwJSFEuSlPgNFwz&currency=${membershipConfiguration.currency}&vault=true`
    );

    useEffect(() => {
        if (
            !membershipConfiguration.is_membership_subscribed &&
            window.paypal
        ) {
            window.paypal
                .Buttons({
                    createSubscription: function (data, actions) {
                        return actions.subscription.create({
                            plan_id: membershipConfiguration.plan_id, //"P-11R30324MD971153RMOHRULA", // Creates the subscription
                            start_time: membershipConfiguration.start_time, //"2023-01-06T00:00:00Z",
                            subscriber: {
                                name: {
                                    given_name: userDetails.first_name, //"Aijaz",
                                    surname: userDetails.last_name, //"Ali",
                                },
                                email_address: userDetails.email, //"aijaz.ali@i-ways.net",
                            },
                            application_context: {
                                brand_name: "Mother's Club",
                                locale: "en-US",
                                user_action: "SUBSCRIBE_NOW",
                                payment_method: {
                                    payer_selected: "PAYPAL",
                                    payee_preferred:
                                        "IMMEDIATE_PAYMENT_REQUIRED",
                                },
                            },
                        });
                    },
                    onApprove: async function (data, actions) {
                        // console.log("paypal onApprove response", { data });
                        try {
                            const response =
                                await notifyOnMembershipPaymentApprove(
                                    userDetails.id,
                                    data
                                );
                            console.log({ response });
                            // TODO: Save profile changes to storage
                            setPaymentStatus("approved");
                            let membershipConfigurationLSJSON =
                                localStorage.getItem(
                                    "membership_configuration"
                                );
                            let membershipConfigurationLS = JSON.parse(
                                membershipConfigurationLSJSON
                            );
                            // membershipConfigurationLS.is_membership_subscribed = true;
                            // membershipConfigurationLS.membership_subscription_status = "Active";
                            membershipConfigurationLS = {
                                ...membershipConfigurationLS,
                                ...response.data.data,
                            };
                            dispatch(
                                saveUserMembershipConfiguration(
                                    membershipConfigurationLS
                                )
                            );
                            localStorage.setItem(
                                "membership_configuration",
                                JSON.stringify(membershipConfigurationLS)
                            );
                            setModalVisibility(true);
                        } catch (err) {
                            console.log("err in paypal onApprove method", err);
                        }
                    },
                    onCancel: async function (data, actions) {
                        console.log("paypal onCancel response", { data });
                        const response = await notifyOnMembershipPaymentCancel(
                            userDetails.id,
                            data
                        );
                        setPaymentStatus("cancelled");
                        setModalVisibility(true);
                        console.log("onCandel response", response);
                    },
                    onError: function (err) {
                        // For example, redirect to a specific error page
                        //window.location.href = "/your-error-page-here";
                        toast.error("Error Occured while processing payment.");
                        console.error("paypal error", err);
                    },
                })
                .render("#paypal-button-container"); // Renders the PayPal button
        }
    }, [paypalScript]);

    return (
        <div className={styles.section}>
            <div className={cn("h2", styles.title)}>Payment methods</div>

            {!membershipConfiguration.is_membership_subscribed ? (
                <div className={cn(styles.box, styles.paypalBtnContainer)}>
                    <div id="paypal-button-container" />
                </div>
            ) : (
                <div className={styles.box}>
                    <div className={styles.category}>
                        Membership Subscription
                    </div>
                    <div className={styles.item}>
                        <div className={styles.details}>
                            <section className={styles.infoSection}>
                                <span className={styles.label}>Status:</span>
                                <span
                                    className={cn(styles.status, {
                                        [styles.error]:
                                            !membershipConfiguration.is_membership_subscribed,
                                    })}
                                >
                                    {membershipConfiguration.is_membership_subscribed
                                        ? "Active"
                                        : "De-active"}
                                </span>
                            </section>
                            {membershipConfiguration.subscription_id && (
                                <section className={styles.infoSection}>
                                    <span className={styles.label}>
                                        Subscription Id:
                                    </span>
                                    <span className={cn(styles.value)}>
                                        {
                                            membershipConfiguration.subscription_id
                                        }
                                    </span>
                                </section>
                            )}
                            {typeof membershipConfiguration.trial_days_left ===
                                "number" && (
                                <section className={styles.infoSection}>
                                    <span className={styles.label}>
                                        Trial Period:
                                    </span>
                                    <span className={cn(styles.value)}>
                                        {membershipConfiguration.trial_days_left >
                                        0
                                            ? `${
                                                  membershipConfiguration.trial_days_left
                                              } ${
                                                  membershipConfiguration.trial_days_left >
                                                  1
                                                      ? "Days"
                                                      : "Day"
                                              } Left (${
                                                  membershipConfiguration.date_end_trial_period
                                              })`
                                            : `Trial Period Ended (${membershipConfiguration.date_end_trial_period})`}
                                    </span>
                                </section>
                            )}
                            {membershipConfiguration.next_billing_time && (
                                <section className={styles.infoSection}>
                                    <span className={styles.label}>
                                        Renewal Date:
                                    </span>
                                    <span className={cn(styles.value)}>
                                        {
                                            membershipConfiguration.next_billing_time
                                        }
                                    </span>
                                </section>
                            )}
                        </div>
                        {/* <button className={cn("button-small", styles.button)}>Cancel Membership</button> */}
                    </div>
                </div>
            )}
            <NotificationModal
                visibility={modalVisibility}
                onClose={() => setModalVisibility(false)}
                outerClassName={styles.infoModal}
                status={paymentStatus === "approved" ? "success" : "warning"}
                message={
                    paymentStatus === "approved"
                        ? "Membership subscription successful."
                        : "Membership subscription canceled."
                }
            />
        </div>
    );
};

export default PaymentsMethod;
