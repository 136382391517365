import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import routes from "routing";
import { store } from "store";
import axios from "utils/axios";

import "react-toastify/dist/ReactToastify.css";
import "react-dates/lib/css/_datepicker.css";
import "./styles/app.sass";

function App() {
    axios.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            // console.log("response from interceptor", response);
            return response;
        },
        function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            console.error("error from interceptor", error);
            if (
                error &&
                error.response &&
                error.response.data.message
                    .toLowerCase()
                    .includes("invalid token")
            ) {
                localStorage.removeItem("jwt_access_token");
                localStorage.removeItem("user_data");
                localStorage.removeItem("membership_configuration");
                window.history.go("/");
            }
            return Promise.reject(error);
        }
    );

    const access_token = localStorage.getItem("jwt_access_token");

    if (access_token) {
        axios.defaults.baseURL = process.env.REACT_APP_API_URL;
        axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    }

    return (
        <Provider store={store}>
            <ToastContainer
                position="top-right"
                hideProgressBar
                pauseOnHover
                autoClose={3000}
            />
            <RouterProvider router={routes} />
        </Provider>
    );
}

export default App;
