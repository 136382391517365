import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import * as dayjs from "dayjs";

import styles from "./Retreat.module.sass";
import Play from "../../../themeComponents/Play";
import Gallery from "themeComponents/Product/Gallery";
import Icon from "components/Icon";
import { toast } from "react-toastify";
import { fetchRetreatTitle } from "utils/retreatsService";

const Retreat = ({ retreat, loading }) => {
    const [title, setTitle] = useState("");

    useEffect(() => {
        const fetchTitle = async () => {
            try {
                const response = await fetchRetreatTitle();
                setTitle(response?.data?.data?.title);
            } catch (err) {
                console.log("err", err);
                if (err.response) {
                    toast.error(err.response.data.message);
                }
            }
        };
        fetchTitle();
    });

    return (
        <div
            id="retreats"
            className={cn("section", styles.section, {
                [styles.withContent]: retreat,
            })}
        >
            <div className={cn("container", styles.container)}>
                <div className={styles.head}>
                    <div className={styles.details}>
                        <div className={cn("stage", styles.stage)}>{title}</div>
                       
                        {loading && (
                            <div>
                                <div
                                    className={cn(
                                        "skeleton h2",
                                        styles.titleSkeleton
                                    )}
                                />
                                <div
                                    className={cn(
                                        "skeleton h2",
                                        styles.datesSkeleton
                                    )}
                                />
                                <div
                                    className={cn(
                                        "button",
                                        styles.button,
                                        styles.buttonSkeleton
                                    )}
                                />
                            </div>
                        )}
                        {retreat ? (
                            <h2 className={cn("h2", styles.title)}>
                                <Link
                                    className={cn("h2", styles.titleLink)}
                                    to={`/retreat/${retreat.event_id}`}
                                >
                                    {retreat.event_Name}
                                </Link>
                            </h2>
                        ) : (
                            // <div
                            //     className={cn(
                            //         "skeleton h2",
                            //         styles.titleSkeleton
                            //     )}
                            // />
                            <p>No Retreat found!</p>
                        )}
                        {retreat ? (
                            <div className={cn("stage", styles.stage)}>
                                {retreat.Address}, {retreat.country_Name}
                            </div>
                        ) : (
                            // <div
                            //     className={cn(
                            //         "skeleton h2",
                            //         styles.datesSkeleton
                            //     )}
                            // />
                            ""
                        )}
                        {/* {retreat ? (
							<div className={cn("stage", styles.stage)}>
								from {dayjs(retreat.start_event_date, "YYYY-MM-DD").format("MMM DD, YYYY")} - to{" "}
								{dayjs(retreat.end_event_date).format("MMM DD, YYYY")}
							</div>
						) : (
							<div className={cn("skeleton h2", styles.datesSkeleton)} />
						)} */}
                    </div>
                    {retreat ? (
                        <Link
                            className={cn("button button-brown", styles.button)}
                            to={`/retreat/${retreat.event_id}`}
                        >
                            {retreat.is_subscribe
                                ? "Your place is saved"
                                : "Join"}
                        </Link>
                    ) : (
                        // <div
                        //     className={cn(
                        //         "button",
                        //         styles.button,
                        //         styles.buttonSkeleton
                        //     )}
                        // />
                        ""
                    )}
                </div>

                <div className={styles.body}>
                    <div className={styles.preview}>
                        {retreat && retreat.event_img.length > 0 && (
                            <img src={retreat.event_img[0]} alt="Main" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Retreat;
