import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Geocode from "react-geocode";
import cn from "classnames";
import { toast } from "react-toastify";

import styles from "./Retreat.module.sass";
import Product from "../../themeComponents/Product";
import Description from "./Description";
import Map from "components/Map";
import Loader from "themeComponents/Loader";

import { fetchRetreatById, toggleRetreatFavoriteStatus } from "utils/retreatsService";
import { saveSelectedRetreat, updateFavoriteStatusForRetreat } from "store/retreatsSlice";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
Geocode.enableDebug();

const socials = [
	{
		title: "twitter",
		url: "https://twitter.com/ui8",
	},
	{
		title: "instagram",
		url: "https://www.instagram.com/ui8net/",
	},
	{
		title: "facebook",
		url: "https://www.facebook.com/ui8.net/",
	},
];

const Retreat = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const [purchaseDone, setPurchaseDone] = useState(false);

	const selectedRetreat = useSelector((state) => state.retreats.selectedRetreat);

	const [options, setOptions] = useState([]);
	const [latitude, setLatitude] = useState(0);
	const [longitude, setLongitude] = useState(0);
	const [loading, setLoading] = useState(true);
	const [loadingMap, setLoadingMap] = useState(true);

	const fetchDataOnMount = async () => {
		const { retreatId } = params;
		setLoading(true);
		try {
			const response = await fetchRetreatById(retreatId);
			dispatch(saveSelectedRetreat(response.data.data));
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		} finally {
			setLoading(false);
		}
	};

	const handleFavoriteStatusUpdate = async () => {
		const { retreatId } = params;
		try {
			const response = await toggleRetreatFavoriteStatus(retreatId);
			console.log({ response });
			if (response.data.message.includes("Un-Favorite Event")) {
				dispatch(updateFavoriteStatusForRetreat({ eventId: selectedRetreat.event_id, status: false }));
				toast.warning("Removed from Favorites Successfully!");
			} else {
				dispatch(updateFavoriteStatusForRetreat({ eventId: selectedRetreat.event_id, status: true }));
				toast.success("Added to Favorites Successfully!");
			}
		} catch (err) {
			console.log("err", err);
			if (err.response) {
				toast.error(err.response.data.message);
			}
		}
	};

	useEffect(() => {
		if (selectedRetreat) {
			setLoadingMap(true);

			const newAddress = `${selectedRetreat.Address} ${selectedRetreat.state_Name}, ${selectedRetreat.country_Name}`;
			Geocode.fromAddress(newAddress).then(
				(response) => {
					const { lat, lng } = response.results[0].geometry.location;
					setLatitude(lat);
					setLongitude(lng);
					setLoadingMap(false);
				},
				(error) => {
					console.log("err loading coordinates", error);
					setLoadingMap(false);
				}
			);

			setOptions((oldOptions) => [
                {
                    title: `${selectedRetreat.Address} ${selectedRetreat.state_Name}, ${selectedRetreat.country_Name}`,
                    icon: "flag",
                },
            ]);
		}
	}, [selectedRetreat]);

	useEffect(() => {
		fetchDataOnMount();
		return () => {
			dispatch(saveSelectedRetreat(null));
		};
	}, []);

	const maps = (
		<>
			<h4 className={cn("h4", styles.title)}>Location details</h4>
			<section className={styles.mapsContainer}>
				<Map
					loading={loadingMap}
					zoom={10}
					defaultCenter={[latitude, longitude]}
					pointers={[
						{
							latitude,
							longitude,
						},
					]}
				/>
			</section>
		</>
	);

	if (loading) {
		return (
			<div className={cn(styles.section)}>
				<div className={cn("container", styles.container, styles.loaderContainer)}>
					<Loader className={styles.loader} />
				</div>
			</div>
		);
	}

	return (
		<>
			<Product
				classSection="section-mb64"
				urlHome="/ceremonies"
				title={selectedRetreat.event_Name}
				breadcrumbs={[]}
				options={options}
				gallery={selectedRetreat.event_img}
				favoriteStatus={selectedRetreat.is_favorite}
				handleFavoriteStatusUpdate={handleFavoriteStatusUpdate}
				type="cars"
				coordinates={{ latitude, longitude }}
			/>
			<Description
				classSection="section"
				retreat={selectedRetreat}
				maps={maps}
			/>
		</>
	);
};

export default Retreat;
