import axios from "./axios";

export const fetchRetreats = async () => {
	const access_token = localStorage.getItem("jwt_access_token");
	const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };
	try {
		const formData = new FormData();
		formData.append("retreat", true);
		const response = await axios.post("/events/core-events/getAllEvents", formData, {
			headers: headers,
		});
		return response;
	} catch (err) {
		throw err;
	}
};

export const fetchRetreatTitle = async () => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        const response = await axios.get(
            "/members/FrontendRetreatTitle/getActiveRetreatTitle",
            {
                headers: headers,
            }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const fetchSubscribedRetreats = async () => {
	const access_token = localStorage.getItem("jwt_access_token");
	const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };
	try {
		// const formData = new FormData();
		// formData.append("retreat", false);
		const response = await axios.get("/events/core-event-subscriptions/get-all-subscribe-user-event?type=subscribe", {
			headers: headers,
		});
		return response;
	} catch (err) {
		throw err;
	}
};

export const fetchFavoriteRetreats = async () => {
	const access_token = localStorage.getItem("jwt_access_token");
	const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };
	try {
		// const formData = new FormData();
		// formData.append("retreat", false);
		const response = await axios.get("/events/core-event-subscriptions/get-all-subscribe-user-event?type=favorite", {
			headers: headers,
		});
		return response;
	} catch (err) {
		throw err;
	}
};

export const fetchRetreatById = async (id) => {
	const access_token = localStorage.getItem("jwt_access_token");
	const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };
	try {
		const formData = new FormData();
		formData.append("id", id);

		const response = await axios.post("/events/core-events/getEventById", formData, {
			headers: headers,
		});
		return response;
	} catch (err) {
		throw err;
	}
};

export const subscribeRetreatById = async (
    userId,
    eventId,
    ticketPrice,
    reference_id,
    options_ceremony_ids
) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("event_id", eventId);
        formData.append("no_of_tickets", "1");
        formData.append("ticket_price", ticketPrice);
        formData.append("reference_id", reference_id);
        formData.append(
            "options_ceremony_ids",
            JSON.stringify(options_ceremony_ids)
        );

        const response = await axios.post(
            "/events/core-event-subscriptions/subscribe-event",
            formData,
            { headers }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const unsubscribeRetreatById = async (eventId) => {
	const access_token = localStorage.getItem("jwt_access_token");
	const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };
	try {
		const formData = new FormData();
		formData.append("event_id", eventId);

		const response = await axios.post("/events/core-event-subscriptions/un-subscribe-event", formData, { headers });
		return response;
	} catch (err) {
		throw err;
	}
};

export const toggleRetreatFavoriteStatus = async (eventId) => {
	const access_token = localStorage.getItem("jwt_access_token");
	const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };
	try {
		const formData = new FormData();
		formData.append("event_id", eventId);

		const response = await axios.post("/events/core-event-subscriptions/favorite-event", formData, { headers });
		return response;
	} catch (err) {
		throw err;
	}
};
