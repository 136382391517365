import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import * as dayjs from "dayjs";

import styles from "./CardSkeleton.module.sass";
import Icon from "components/Icon";

const Item = ({ className }) => {
	return (
		<div className={cn("skeleton", className, styles.card)}>
			<div className={styles.preview}>
				{/* <img
					srcSet={`${item.event_img[0]} 2x`}
					src={item.event_img[0]}
					alt="Nature"
				/>
				{item.categoryText && <div className={cn("category", { "category-blue": item.category === "blue" }, styles.category)}>{item.categoryText}</div>} */}
			</div>
			<div className={styles.body}>
				<div className={styles.line}>
					<div className={cn("skeleton", styles.title)} />
					<div className={styles.price} />
				</div>
				<div className={styles.options}>
					<div className={styles.option}>
						<Icon
							name="geo-pin"
							size="16"
						/>
						<p className={cn("skeleton", styles.address)}></p>
					</div>
				</div>
				<div className={styles.foot}>
					<div className={styles.flex}>
						<div className={styles.datesContainer}>
							<div className={cn("skeleton", styles.date)} />
							<div className={cn("skeleton", styles.date2)} />
						</div>
						<div className={styles.tickets}>
							<Icon
								name="ticket"
								size="20"
							/>
							<div className={styles.ticketStatsContainer}>
								<div className={cn("skeleton", styles.ticketStats)} />
								<div className={cn("skeleton", styles.ticketStats2)} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Item;
