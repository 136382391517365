import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	retreatsList: [],
	subscribedRetreatsList: [],
	favoriteRetreatsList: [],
	selectedRetreat: null,
};

const retreatsSlice = createSlice({
	name: "Retreats",
	initialState,
	reducers: {
		saveRetreatsList: (state, action) => {
			state.retreatsList = action.payload;
		},
		saveSubscribedRetreatsList: (state, action) => {
			state.subscribedRetreatsList = action.payload;
		},
		saveFavoriteRetreatsList: (state, action) => {
			state.favoriteRetreatsList = action.payload;
		},
		saveSelectedRetreat: (state, action) => {
			state.selectedRetreat = action.payload;
		},
		updateSubscriptionStatusForRetreat: (state, action) => {
			// const retreatId = action.payload.retreatId;
			const status = action.payload.status;
			state.selectedRetreat.is_subscribe = status;
			state.selectedRetreat.is_payment = status;
			if (status) {
				state.selectedRetreat.numerOfSeatsLeft = state.selectedRetreat.numerOfSeatsLeft - 1;
			} else if (!status) {
				state.selectedRetreat.numerOfSeatsLeft = state.selectedRetreat.numerOfSeatsLeft + 1;
			}
		},
		updateFavoriteStatusForRetreat: (state, action) => {
			console.log("got called", action);
			const retreatId = action.payload.eventId;
			if (state.selectedRetreat) {
				state.selectedRetreat.is_favorite = action.payload.status;
			}
			// const indexOfRetreat = state.retreatsList.indexOf((retreat) => retreat.id === retreatId);
			// if (indexOfRetreat) {
			// 	state.retreatsList[indexOfRetreat].is_favorite = action.payload.status;
			// }
		},
	},
});

export const {
	saveRetreatsList,
	saveSubscribedRetreatsList,
	saveFavoriteRetreatsList,
	saveSelectedRetreat,
	updateSubscriptionStatusForRetreat,
	updateFavoriteStatusForRetreat,
} = retreatsSlice.actions;

export default retreatsSlice.reducer;
