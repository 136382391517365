import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./Description.module.sass";
import Icon from "../../../themeComponents/Icon";
import Details from "./Details";
import Receipt from "../../../themeComponents/Receipt";
import Counter from "../../../themeComponents/Counter";
import Modal from "components/Modal";
import Loader from "themeComponents/Loader";
import NotificationModal from "components/NotificationModal";
import PaymentModal from "components/PaymentModal";

import {
    subscribeCeremonyById,
    unsubscribeCeremonyById,
} from "utils/ceremoniesService";
import { updateSubscriptionStatusForCeremony } from "store/ceremoniesSlice";
import {
    handleEventOptionsForPayment,
    notifyOnEventPaymentApprove,
    notifyOnEventPaymentCancel,
} from "utils/paymentService";

const returnCurrencyText = (symbol) => {
    switch (symbol) {
        case "€":
            return "E";
        case "$":
            return "Dollar";
        default:
            return "Pound";
    }
};

const Description = ({ classSection, ceremony, maps, setPurchaseDone }) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.auth.user.id);

    const [subscribed, setSubcribed] = useState(false);
    const [paymented, setPaymented] = useState(false);

    const [service, setService] = useState(0);
    const [seat, setSeat] = useState(0);
    const [processing, toggleProcessing] = useState(false);
    const [subscribeApiResponseIcon, setSubscribeApiResponseIcon] =
        useState("");
    const [subscribeApiResponseMessage, setSubscribeApiResponseMessage] =
        useState("");
    const [showModal, toggleShowModal] = useState(false);
    const [paymentModal, togglePaymentModal] = useState(false);
    const [optionsCeremonyId, setOptionsCeremonyId] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [totalBasePrice, setTotalBasePrice] = useState(0);
    const [ceremonyOptions, setCeremonyOptions] = useState(
        ceremony?.options_ceremony || []
    );
    const [totalPrice, setTotalPrice] = useState(0);

    const handleOptions = (e) => {
        const { value, checked } = e.target;

        const selectedOptionPrice = ceremonyOptions.filter(
            (item) => item?.option_ceremony_id === +value
        )[0];

        if (checked) {
            setTotalPrice((prev) => {
                if (
                    selectedOptionPrice?.price === 0 ||
                    selectedOptionPrice?.price == null
                ) {
                    return prev + ceremony?.ticket_price;
                } else {
                    return prev + selectedOptionPrice?.price;
                }
            });

            setTotalBasePrice((prev) => prev + selectedOptionPrice?.base_price);
        } else {
            setTotalPrice((prev) => {
                if (
                    selectedOptionPrice?.price === 0 ||
                    selectedOptionPrice?.price == null
                ) {
                    return prev - ceremony?.ticket_price;
                } else {
                    return prev - selectedOptionPrice?.price;
                }
            });
            setTotalBasePrice((prev) => prev - selectedOptionPrice?.base_price);
        }

        setCeremonyOptions((prevState) => {
            let prevStateArray = JSON.parse(JSON.stringify(prevState));
            const selectedOptionIndex = prevStateArray.findIndex(
                (item) => item.option_ceremony_id === +value
            );
            if (selectedOptionIndex > -1) {
                prevStateArray[selectedOptionIndex].is_subscribe =
                    !prevStateArray[selectedOptionIndex].is_subscribe;

                return prevStateArray;
            }
            return prevStateArray;
        });
        if (checked) {
            setOptionsCeremonyId([...optionsCeremonyId, Number(value)]);
        } else {
            const updateCeremonyIds = optionsCeremonyId.filter(
                (item) => item !== +value
            );
            setOptionsCeremonyId(updateCeremonyIds);
        }
    };

    const handleSubscribeSuccessful = async (paypalResponse) => {
        toggleProcessing(true);
        togglePaymentModal(false);

        try {
            const res = await notifyOnEventPaymentApprove(
                userId,
                ceremony.event_id,
                paypalResponse,
                paymentDetails.reference_id
            );

            if (!subscribed) {
                const response = await subscribeCeremonyById(
                    userId,
                    ceremony.event_id,
                    ceremony.ticket_price,
                    paymentDetails.reference_id,
                    optionsCeremonyId
                );
                if (response.data.status === "Success") {
                    setPurchaseDone(true);
                    setSubscribeApiResponseIcon("success");
                    toggleShowModal(true);
                    setSubscribeApiResponseMessage(
                        "Ceremony subscribed successfully!"
                    );

                    dispatch(
                        updateSubscriptionStatusForCeremony({
                            ceremonyId: ceremony.event_id,
                            status: true,
                        })
                    );
                }
            } else {
                if (res.data.status === "Success") {
                    setPurchaseDone(true);
                    setSubscribeApiResponseIcon("success");
                    toggleShowModal(true);
                    setSubscribeApiResponseMessage(
                        "Ceremony subscribed successfully!"
                    );
                }
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            toggleProcessing(false);
        }
    };

    const handleUnsubscribeSuccessful = async () => {
        toggleProcessing(true);
        try {
            const response = await unsubscribeCeremonyById(ceremony.event_id);
            if (response.data.status === "Success") {
                setSubscribeApiResponseIcon("warning");
                setSubscribeApiResponseMessage(
                    "Ceremony unsubscribed Successfully!"
                );
                toggleShowModal(true);
                dispatch(
                    updateSubscriptionStatusForCeremony({
                        ceremonyId: ceremony.event_id,
                        status: false,
                    })
                );
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                if (err.response) {
                    toast.error(err.response.data.message);
                }
            }
        } finally {
            toggleProcessing(false);
        }
    };

    const handleNotifySubscriptionCancel = async (paypalResponse) => {
        // console.log({ paypalResponse });
        try {
            await notifyOnEventPaymentCancel(
                userId,
                ceremony.event_id,
                paypalResponse.orderID
            );
        } catch (err) {
            console.log(err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        }
    };

    const handleSubscribeCeremonyForPayment = async () => {
        toggleProcessing(true);
        const response = await subscribeCeremonyById(
            userId,
            ceremony.event_id,
            ceremony.ticket_price,
            paymentDetails.reference_id,
            optionsCeremonyId
        );
        if (response.data.status === "Success") {
            setPurchaseDone(true);
            toggleProcessing(false);
            setSubscribeApiResponseIcon("success");
            toggleShowModal(true);
            setSubscribeApiResponseMessage("Ceremony subscribed successfully!");

            dispatch(
                updateSubscriptionStatusForCeremony({
                    ceremonyId: ceremony.event_id,
                    status: true,
                })
            );
        }
    };

    const handleOnSubscribe = async () => {
        //  api call to get payment details

        if (ceremony.is_subscribe && !optionsCeremonyId.length) {
            toast.error("Ceremony is already subscribed");
            return;
        }

        try {
            const res = await handleEventOptionsForPayment(
                ceremony.event_id,
                optionsCeremonyId
            );
            setPaymentDetails(res?.data?.data?.payment);
            const is_payment = res?.data?.data?.is_payment;
            const is_subscribe = res?.data?.data?.is_subscribe;

            setPaymented(is_payment);
            setSubcribed(is_subscribe);

            if (is_payment && is_subscribe) {
                toast.success(
                    "This ceremony and options are already subscribed"
                );
            } else if (is_payment && !is_subscribe) {
                handleSubscribeCeremonyForPayment();
            } else {
                togglePaymentModal(true);
            }
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error);
        }
    };

    const handleOnUnsubscribe = async () => {
        handleUnsubscribeSuccessful();
    };

    const disableSubmission = () => {
        let disabled = true;

        ceremonyOptions?.map((c) => {
            if (!(c.is_subscribe && c.is_payment)) {
                disabled = false;
            }
        });

        return disabled;
    };

    const btnTextChange = () => {
        let change = true;

        ceremonyOptions?.map((c) => {
            if (!(c.is_subscribe && c.is_payment)) {
                if (c.seats) {
                    change = false;
                }
            }
        });

        return change;
    };

    const reFactortitle = (
        <div className={styles.reFactortitle}>
            <span>{ceremony.start_event_date}</span>
            <span style={{ textAlign: "center" }}> -</span>
            <span>{ceremony.end_event_date}</span>
        </div>
    );

    return (
        <>
            <div className={cn(classSection, styles.section)}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.wrapper}>
                        <Details
                            className={styles.details}
                            description={ceremony.Description}
                        />
                        <Receipt
                            className={styles.receipt}
                            items={[
                                {
                                    title: reFactortitle,
                                    category: "Date",
                                    icon: "calendar",
                                },
                                {
                                    title: `${
                                        ceremony.numerOfSeatsLeft < 1
                                            ? "Booked Out"
                                            : ceremony.numerOfSeatsLeft +
                                              ` ${
                                                  ceremony.numerOfSeatsLeft > 1
                                                      ? " Places Available"
                                                      : "Place Available"
                                              }`
                                    } `,
                                    category: "Tickets",
                                    // icon: "ticket",
                                },
                            ]}
                            priceActual={
                                ceremony.ticket_price &&
                                ceremony.ticket_price > 0
                                    ? `EUR ${ceremony.ticket_price.toLocaleString()}`
                                    : "Free"
                            }
                            options={ceremony?.options_ceremony}
                        >
                            <section className={styles.box}>
                                {/* {ceremony.is_subscribe && ceremony.is_payment ? (
									<button
										className={cn("button button-brown", styles.button)}
										onClick={handleOnUnsubscribe}
										disabled={processing}
									>
										{processing ? <Loader /> : "Unsubscribe"}
									</button>
								) : (
									<button
										className={cn("button button-brown", styles.button)}
										onClick={handleOnSubscribe}
										disabled={processing || ceremony.numerOfSeatsLeft < 1}
									>
										{processing ? <Loader /> : "Subscribe"}
									</button>
								)} */}
                                <div className={cn(styles.optionsList)}>
                                    {ceremonyOptions?.map((opt) => (
                                        <>
                                            <div
                                                className={
                                                    styles.optionContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.optionAction
                                                    }
                                                >
                                                    {
                                                        <label
                                                            className={
                                                                styles.checkbox
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className={
                                                                    styles.input
                                                                }
                                                                name="selected_options"
                                                                disabled={
                                                                    (opt.is_payment &&
                                                                        opt.is_subscribe) ||
                                                                    ceremony?.is_ceremony_outdated ||
                                                                    (opt.seats
                                                                        ? opt.seats &&
                                                                          opt.seats_left <=
                                                                              0
                                                                        : ceremony.numerOfSeatsLeft <
                                                                          1)
                                                                }
                                                                checked={
                                                                    opt.is_subscribe
                                                                }
                                                                onChange={
                                                                    handleOptions
                                                                }
                                                                value={
                                                                    opt.option_ceremony_id
                                                                }
                                                            />
                                                            <span
                                                                className={
                                                                    styles.inner
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles.tick
                                                                    }
                                                                ></span>
                                                            </span>
                                                        </label>
                                                    }
                                                </div>
                                                <div className={styles.option}>
                                                    {opt.title} |{" "}
                                                    {ceremony?.currency}
                                                    {opt.base_price}-{" "}
                                                    {ceremony?.currency || "€"}
                                                    {opt.price
                                                        ? opt.price
                                                        : ceremony.ticket_price}{" "}
                                                    {opt.seats
                                                        ? opt.seats_left
                                                            ? `${`
                                                            ${opt.seats_left} 
                                                            ${
                                                                opt.seats_left >
                                                                1
                                                                    ? "Places available"
                                                                    : "Place available"
                                                            }`}`
                                                            : "Booked Out"
                                                        : ceremony.numerOfSeatsLeft &&
                                                          ceremony.numerOfSeatsLeft >
                                                              0
                                                        ? `${`
                                                            ${
                                                                ceremony.numerOfSeatsLeft
                                                            } 
                                                            ${
                                                                ceremony.numerOfSeatsLeft >
                                                                1
                                                                    ? "Places available"
                                                                    : "Place available"
                                                            }`}`
                                                        : "Booked Out"}
                                                    {"   "}({opt.start_date} -{" "}
                                                    {"   "}
                                                    {opt.end_date})
                                                </div>
                                            </div>

                                            <br />
                                        </>
                                    ))}
                                </div>{" "}
                                <br />
                                {optionsCeremonyId.length ? (
                                    <div className={styles.totalPriceContainer}>
                                        <p></p>
                                        <p className={styles.totalPrice}>
                                            Deposit Amount:{" "}
                                            {ceremony?.currency || "€"}
                                            {totalPrice}{" "}
                                        </p>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {}
                                {optionsCeremonyId.length ? (
                                    <div className={styles.totalPriceContainer}>
                                        <p></p>
                                        <p className={styles.totalPrice}>
                                            Amount To Be Paid:{" "}
                                            {ceremony?.currency || "€"}
                                            {totalBasePrice}{" "}
                                        </p>
                                    </div>
                                ) : (
                                    ""
                                )}
                                <button
                                    className={cn(
                                        "button button-brown",
                                        styles.button
                                    )}
                                    onClick={handleOnSubscribe}
                                    disabled={
                                        processing ||
                                        (disableSubmission() &&
                                            ceremony.is_subscribe) ||
                                        ceremony.is_ceremony_outdated ||
                                        (ceremony.numerOfSeatsLeft < 1 &&
                                            !optionsCeremonyId.length)
                                    }
                                >
                                    {processing ? (
                                        <Loader />
                                    ) : btnTextChange() &&
                                      ceremony.is_subscribe ? (
                                        "Your place is saved"
                                    ) : (
                                        "Join"
                                    )}
                                </button>
                            </section>
                        </Receipt>
                    </div>
                    {maps}
                </div>
            </div>
            <NotificationModal
                visibility={showModal}
                onClose={() => {
                    toggleShowModal(false);
                    window.location.reload();
                }}
                outerClassName={styles.infoModal}
                status={subscribeApiResponseIcon}
                message={subscribeApiResponseMessage}
            />
            <PaymentModal
                visibility={paymentModal}
                outerClassName={styles.paymentModalContainer}
                onClose={() => togglePaymentModal(false)}
                onApprove={handleSubscribeSuccessful}
                userId={userId}
                referenceId={paymentDetails.reference_id}
                price={paymentDetails.total_price}
                items={paymentDetails.items}
                onCancel={handleNotifySubscriptionCancel}
                currency_code={paymentDetails?.currency_code}
            />
        </>
    );
};

export default Description;
