import axios from "./axios";

export const notifyOnMembershipPaymentApprove = async (userId, paypalResponse) => {
	const access_token = localStorage.getItem("jwt_access_token");
	const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };
	try {
		const formData = new FormData();
		formData.append("core_user_id", userId);
		formData.append("orderID", paypalResponse.orderID);
		formData.append("subscriptionID", paypalResponse.subscriptionID);
		formData.append("facilitatorAccessToken", paypalResponse.facilitatorAccessToken);
		formData.append("paymentSource", paypalResponse.paymentSource);
		const response = await axios.post("/members/frontend-membership-subscriptions/subscribed", formData, {
			headers: headers,
		});
		return response;
	} catch (err) {
		throw err;
	}
};

export const notifyOnMembershipPaymentCancel = async (userId, orderID) => {
	const access_token = localStorage.getItem("jwt_access_token");
	const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };
	try {
		const formData = new FormData();
		formData.append("core_user_id", userId);
		formData.append("orderID", orderID);
		const response = await axios.post("/members/frontend-membership-subscriptions/cancelledMembership", formData, {
			headers: headers,
		});
		return response;
	} catch (err) {
		throw err;
	}
};

export const notifyOnEventPaymentApprove = async (
    userId,
    eventId,
    paypalResponse,
    reference_id
) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        formData.append("core_user_id", userId);
        formData.append("core_event_id", eventId);
        formData.append("accelerated", paypalResponse.accelerated ? 1 : 0);
        formData.append("orderID", paypalResponse.orderID);
        formData.append("payerID", paypalResponse.payerID);
        formData.append("paymentID", paypalResponse.paymentID);
        formData.append("billingToken", paypalResponse.billingToken);
        formData.append(
            "facilitatorAccessToken",
            paypalResponse.facilitatorAccessToken
        );
        formData.append("paymentSource", paypalResponse.paymentSource);
        formData.append("reference_id", reference_id);
        const response = await axios.post(
            "/members/frontend-event-subscriptions/payment-event-subscriptions",
            formData,
            {
                headers: headers,
            }
        );
        console.log("notifyevent", response);
        return response;
    } catch (err) {
        throw err;
    }
};

export const notifyOnEventPaymentCancel = async (userId, eventId, orderID) => {
	const access_token = localStorage.getItem("jwt_access_token");
	const headers = { "Content-Type": "application/json", Authorization: `Bearer ${access_token}` };
	try {
		const formData = new FormData();
		formData.append("core_user_id", userId);
		formData.append("core_event_id", eventId);
		formData.append("orderID", orderID);
		const response = await axios.post("/members/frontend-event-subscriptions/cancelEventPayment", formData, {
			headers: headers,
		});
		return response;
	} catch (err) {
		throw err;
	}
};

export const handleEventOptionsForPayment = async (eventId, options_ceremony_ids) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        formData.append("id", eventId);
        formData.append("options_ceremony_ids", JSON.stringify(options_ceremony_ids));
        const response = await axios.post(
            "/events/core-events/getEventOptionsForPayment",
            formData,
            {
                headers: headers,
            }
        );
        return response;
    } catch (err) {
        throw err;
    }
};
