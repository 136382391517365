import React from "react";
import styles from "./Newsletter.module.sass"; // Import the module Sass file
import { useLocation } from "react-router-dom";
import axios from "utils/axios";
import Loader from "themeComponents/Loader";

export default function NewsletterSubscription() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const [status, setStatus] = React.useState("");
    const [name, setName] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const fetchMessage = async () => {
        setLoading(true);
        await axios
            .get(
                `https://api-mother.pwr.dev/members/FrontendNewsLetterSubscribers/newsletterSubscriptionApi?code=${code}`
            )
            .then((res) => {
                const { message } = res.data;
                const { name } = res.data;
                setStatus(message);
                setName(name);
            })
            .catch((err) => {
                const { message } = err.response.data;
                setStatus(message);
            })
            .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        fetchMessage();
    }, [code]);

    return (
        <div className={styles.newsletter}>
            <div className={styles.newsletter_50}>
                {loading ? (
                    <div className={styles.loader_center}>
                        <Loader color="red" />
                    </div>
                ) : (
                    <>
                        <h1>{name ? <>Hello {name},</> : "Hello,"}</h1>

                        {status === "success" && (
                            <>
                                <p className={styles.newsletter_p}>
                                    Thank you for becoming a member of Mother
                                    Club!
                                </p>
                                <p className={styles.newsletter_p}>
                                    We are grateful for your decision to
                                    subscribe and join our community. Your
                                    support means a lot to us, and we are
                                    committed to delivering the best possible
                                    experience to you.
                                </p>
                                <p className={styles.newsletter_p}>
                                    Get ready to enjoy exclusive content,
                                    updates, and special offers.
                                </p>
                                <p className={styles.newsletter_p}>
                                    If you have any inquiries or feedback,
                                    please feel free to reach out. We appreciate
                                    your trust in us and look forward to serving
                                    you!
                                </p>
                            </>
                        )}

                        {/* Server Error */}

                        {status === "server-error" && (
                            <>
                                <p className={styles.newsletter_p}>
                                    We apologize for the inconvenience. It seems
                                    we're experiencing a temporary issue with
                                    our server.
                                </p>
                                <p className={styles.newsletter_p}>
                                    Our team is actively working to resolve the
                                    problem and restore normal service as soon
                                    as possible.
                                </p>
                                <p className={styles.newsletter_p}>
                                    We appreciate your patience and
                                    understanding. Please check back shortly.
                                </p>
                            </>
                        )}

                        {/* code invalid */}

                        {status === "invalid-code" && (
                            <>
                                <p className={styles.newsletter_p}>
                                    We're sorry, but it appears that the code
                                    you entered is invalid.
                                </p>
                                <p className={styles.newsletter_p}>
                                    Please double-check the code and try again.
                                </p>
                                <p className={styles.newsletter_p}>
                                    If you believe this is an error, please
                                    contact our customer support team for
                                    assistance.
                                </p>
                                <p className={styles.newsletter_p}>
                                    We apologize for any inconvenience this may
                                    have caused.
                                </p>
                                <p className={styles.newsletter_p}>
                                    Thank you for your understanding!
                                </p>
                            </>
                        )}
                        <p className={styles.newsletter_p}>
                            The Mother Club Team
                        </p>
                    </>
                )}
            </div>
        </div>
    );
}
