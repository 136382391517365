import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import ceremoniesReducer from "./ceremoniesSlice";
import articlesReducer from "./articlesSlice";
import retreatsReducer from "./retreatsSlice";

export const store = configureStore({
	reducer: {
		auth: authReducer,
		ceremonies: ceremoniesReducer,
		articles: articlesReducer,
		retreats: retreatsReducer,
	},
});
