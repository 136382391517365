import React, { useState, useEffect } from "react";
import cn from "classnames";
import { toast } from "react-toastify";
import { changePasswordService, checkTokenExpiry } from "utils/authService";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import styles from "./Form.module.sass";
import TextInput from "themeComponents/TextInput";
import Loader from "themeComponents/Loader";
import { useParams, useNavigate, Link } from "react-router-dom";

const passwordShapeErr =
    "Password must be atleast 8 characters long. And should include one capital letter, one special character and one number.";

const schema = yup.object().shape({
    password: yup
        .string()
        .required("You must enter a Password.")
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
            passwordShapeErr
        ),
    repeatPassword: yup
        .string()
        .required("Please enter confirm password")
        .oneOf([yup.ref("password")], "Passwords must match"),
    token: yup.string().required("You must have a valid token"),
});

const ResetPasswordForm = ({ setPasswordChange =() =>{}, tokenExpire, setTokenExpire = ()=>{} }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [processing, toggleProcessing] = useState(false);
    

    const methods = useForm({
        mode: "onTouched",
        defaultValues: {
            password: "",
            repeatPassword: "",
            token: "",
        },
        resolver: yupResolver(schema),
    });
    const {
        reset,
        watch,
        control,
        formState: { isValid, isDirty },
        getValues,
        setValue,
    } = methods;
    const form = watch();

    const formSubmitHandler = async (e) => {
        e.preventDefault();
        const allValues = getValues();
        toggleProcessing(true);

        try {
            const registerResponse = await changePasswordService(
                allValues.password,
                allValues.repeatPassword,
                allValues.token
            );
            if (registerResponse.data.status === "Success") {
                toast.success("Successfully Changed");
				setPasswordChange(true);
            } else if (registerResponse.data.status === "Failed") {
                toast.error("Error Occured");
				
            }
        } catch (err) {
            console.log("err", err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            toggleProcessing(false);
        }
    };

    useEffect(() => {
        if (params?.token) {
            setValue("token", params?.token);
        }
    }, []);

    return (
        <>
            {!tokenExpire && (
                <div className={cn(styles.login)}>
                    <div className={styles.item}>
                        <form
                            className={styles.form}
                            onSubmit={formSubmitHandler}
                        >
                            <Controller
                                name="password"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextInput
                                        {...field}
                                        className={styles.field}
                                        type="password"
                                        view
                                        placeholder="Enter your Password"
                                        required
                                        error={error}
                                        errorMessage={error?.message}
                                    />
                                )}
                            />

                            <Controller
                                name="repeatPassword"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextInput
                                        {...field}
                                        className={styles.field}
                                        type="password"
                                        view
                                        placeholder="Confirm your Password"
                                        required
                                        error={error}
                                        errorMessage={error?.message}
                                    />
                                )}
                            />

                            <button
                                className={cn("button", styles.button)}
                                type="submit"
                                disabled={processing || !isValid || !isDirty}
                            >
                                {processing ? (
                                    <Loader color="white" />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default ResetPasswordForm;
