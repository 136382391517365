import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import debounce from "lodash/debounce";

import Dropdown from "./Dropdown";

import styles from "./Navbar.module.sass";

const dashboardNavigationOptions = [
    {
        title: "Ceremonies",
        url: "/#ceremonies",
        icon: "email",
        id: "ceremonies",
    },
    {
        title: "Articles",
        url: "/#startArticles",
        icon: "comment",
        id: "articles",
    },
    {
        title: "Retreats",
        url: "/#retreats",
        icon: "comment",
        id: "retreats",
    },
];

const Navbar = () => {
    const navigate = useNavigate();
    let observerRef = useRef(false);
    const authState = useSelector((state) => state.auth.authState);
    const retreatsList = useSelector((state) => state.retreats.retreatsList);

    const [navStick, setNavStick] = useState(false);
    const [options, setOptions] = useState([]);
    const [category, setCategory] = useState(null);
    const [activeNavId, setActiveNavId] = useState("ceremonies");

    const determineSectionPosition = (id) => {
        const element = document.getElementById(id);
        if (!element) return false;
        const elementTop = window.scrollY + element.getBoundingClientRect().top;
        if (window.scrollY + 70 >= elementTop) {
            return true;
        }
    };

    const scrollToDiv = (id) => {
        const element = document.getElementById(id);
        if (!element) return false;
        console.log(typeof element);
        const elementTop = window.scrollY + element.getBoundingClientRect().top;

        window.scrollTo({
            top: elementTop - 50,
            behavior: "smooth",
        });

        setActiveNavId(id);
    };

    const determineStickyPosition = () => {
        const navbar = document.getElementById("headerNavBar");
        const activeDivIndex = dashboardNavigationOptions.findLastIndex((f) =>
            determineSectionPosition(f.id)
        );

        if (activeDivIndex > -1)
            setActiveNavId(dashboardNavigationOptions[activeDivIndex].id);

        if (window.scrollY <= 80) {
            setNavStick(false);
        } else if (
            observerRef.current.isIntersecting &&
            navbar.getBoundingClientRect().y <= 0
        ) {
            setNavStick(true);
        }
    };

    useEffect(() => {
        const options = dashboardNavigationOptions.map((x) => x.title);
        setOptions(options);
        setCategory(options[0]);
    }, [dashboardNavigationOptions]);

    useEffect(() => {
        if (IntersectionObserver) {
            var observer = new IntersectionObserver(
                function (entries) {
                    const entry = entries[0];
                    observerRef.current = entry;
                },
                { threshold: [0] }
            );

            observer.observe(document.getElementById("headerNavContainer"));
        }

        // const debouncedFunc = debounce(determineStickyPosition, 500);
        document.addEventListener("scroll", determineStickyPosition);
        return () => {
            document.removeEventListener("scroll", determineStickyPosition);
        };
    }, []);

    return (
        <>
            {authState && (
                <div
                    id="headerNavContainer"
                    className={cn("container", styles.container)}
                >
                    <span
                        className={cn({
                            [styles.containerStickyAdjustment]: navStick,
                        })}
                    />
                    <div
                        id="headerNavBar"
                        className={cn(styles.top, {
                            [styles.sticky]: navStick,
                        })}
                    >
                        <div className={styles.nav}>
                            {dashboardNavigationOptions.map((x, index) => (
                                <div
                                    className={cn(styles.btn, "cursorPointer", {
                                        [styles.active]: x.id === activeNavId,
                                    })}
                                    key={index}
                                    // href={x.url}
                                    onClick={() => {
                                        setCategory(x.title);
                                        scrollToDiv(x.id);
                                    }}
                                >
                                    {x.title}
                                </div>
                            ))}
                        </div>
                        <Dropdown
                            className={cn(styles.dropdown, {
                                [styles.stickyDropdown]: navStick,
                            })}
                            value={category}
                            setValue={setCategory}
                            options={dashboardNavigationOptions}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default Navbar;
