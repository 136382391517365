import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import debounce from "lodash/debounce";

import Icon from "components/Icon";
import Dropdown from "themeComponents/Dropdown";

import styles from "./Articles.module.sass";

const Articles = ({ list, loading, stickyAllowed = false }) => {
	let observerRef = useRef(false);
	const authState = useSelector((state) => state.auth.authState);
	const membershipConfiguration = useSelector((state) => state?.auth?.membershipConfiguration);

	const [sortedList, setSortedList] = useState([]);
	const [options, setOptions] = useState([]);
	const [category, setCategory] = useState(null);
	const [selectedArticle, setSelectedArticle] = useState(null);
	const [navStick, setNavStick] = useState(false);

	const determineStickyPosition = () => {
		const articleStarter = document.getElementById("articleStarter");

		if (observerRef.current.isIntersecting && articleStarter.getBoundingClientRect().y <= 0) {
			setNavStick(true);
		} else {
			if (articleStarter.getBoundingClientRect().y >= 10) {
				setNavStick(false);
			} else if (!observerRef.current.isIntersecting) {
				setNavStick(false);
			}
		}
	};

	useEffect(() => {
		if (list.length) {
			const res = [...list].sort((a, b) => parseFloat(a.sort_number) - parseFloat(b.sort_number));
			const options = res.map((x) => x.title);
			setOptions(options);

			setCategory(options[0]);
		}
	}, [list]);

	useEffect(() => {
		const filteredArticle = list.find((x) => x.title === category);
		setSelectedArticle(filteredArticle);
	}, [category]);

	useEffect(() => {
		if (stickyAllowed) {
			if (IntersectionObserver) {
				var observer = new IntersectionObserver(
					function (entries) {
						const entry = entries[0];
						observerRef.current = entry;
					},
					{ threshold: [0.1] }
				);

				observer.observe(document.getElementById("articleContainer"));
			}

			// const debouncedFunc = debounce(determineStickyPosition, 500);
			document.addEventListener("scroll", determineStickyPosition);
			return () => {
				document.removeEventListener("scroll", determineStickyPosition);
			};
		}
	}, []);

	const check = () => authState && membershipConfiguration && membershipConfiguration.is_membership_subscribed;

	let content = (
		<>
			{loading ? (
				<div className={cn(styles.top, { [styles.sticky]: stickyAllowed && navStick, [styles.stickySecondary]: check() })}>
					<div className={styles.nav}>
						{[1, 2, 3, 4, 5].map((x, index) => (
							<span
								key={x}
								className={cn(`skeleton ${styles.btnSkeleton}`)}
							/>
						))}
					</div>
				</div>
			) : (
				<div className={cn(styles.top, { [styles.sticky]: stickyAllowed && navStick, [styles.stickySecondary]: check() })}>
					<div className={styles.nav}>
						{options.map((x, index) => (
							<button
								className={cn(styles.btn, {
									[styles.active]: x === category,
								})}
								key={index}
								onClick={() => setCategory(x)}
							>
								{x}
							</button>
						))}
					</div>
					<Dropdown
						className={cn(styles.dropdown, { [styles.stickyDropdown]: stickyAllowed && navStick })}
						value={category}
						setValue={setCategory}
						options={options}
					/>
				</div>
			)}

			{loading ? (
				<div className={styles.head}>
					<div className={styles.paragraphSkeleton}>
						{[1, 2, 3, 4, 5].map((x, index) => (
							<div
								key={x}
								className={cn(`skeleton`)}
							></div>
						))}
					</div>
					<div className={styles.paragraphSkeleton}>
						{[1, 2, 3, 4, 5].map((x, index) => (
							<div
								key={x}
								className={cn(`skeleton`)}
							></div>
						))}
					</div>
					<div className={styles.paragraphSkeleton}>
						{[1, 2, 3, 4, 5].map((x, index) => (
							<div
								key={x}
								className={cn(`skeleton`)}
							></div>
						))}
					</div>
				</div>
			) : (
				<div
					id="articles"
					className={styles.head}
					dangerouslySetInnerHTML={{
						__html: selectedArticle?.Description,
					}}
				/>
			)}
		</>
	);

	if (list.length < 1 && !loading) {
		content = <p className={styles.noArticleHeading}>No articles available to show.</p>;
	}

	return (
        <>
            <span id="articleStarter" />
            <div
				id="articles"
                className={cn("section", styles.section)}
            >
                <div
                    id="articleContainer"
                    className={cn("container", styles.container)}
                >
                    <div
                        className={cn(styles.head, {
                            [styles.containerStickyAdjustment]:
                                stickyAllowed && navStick,
                        })}
                    >
                        <h2 className={cn("h2", styles.title)}>Articles</h2>
                    </div>

                    {content}
                </div>
            </div>
        </>
    );
};

export default Articles;
