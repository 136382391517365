import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	authState: false,
	user: null,
	membershipConfiguration: null,
};

export const authSlice = createSlice({
	name: "Auth",
	initialState,
	reducers: {
		saveUserData: (state, action) => {
			state.user = action.payload;
		},
		saveUserMembershipConfiguration: (state, action) => {
			state.membershipConfiguration = action.payload;
		},
		toggleAuthState: (state, action) => {
			state.authState = action.payload;
		},
		removeUserData: (state) => {
			state.user = null;
		},
		updateNewsletterSubscriptionStatus: (state, action) => {
			state.user.is_newsletter_subscriber = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { toggleAuthState, saveUserData, saveUserMembershipConfiguration, removeUserData, updateNewsletterSubscriptionStatus } = authSlice.actions;

export default authSlice.reducer;
