import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { toast } from "react-toastify";

import styles from "./Description.module.sass";
import Icon from "../../../themeComponents/Icon";
import Details from "./Details";
import Receipt from "../../../themeComponents/Receipt";
import Modal from "components/Modal";
import Loader from "themeComponents/Loader";
import NotificationModal from "components/NotificationModal";
import PaymentModal from "components/PaymentModal";

import {
    subscribeRetreatById,
    unsubscribeRetreatById,
} from "utils/retreatsService";
import {
    handleEventOptionsForPayment,
    notifyOnEventPaymentApprove,
    notifyOnEventPaymentCancel,
} from "utils/paymentService";
import { updateSubscriptionStatusForRetreat } from "store/retreatsSlice";

const Description = ({ classSection, retreat, maps }) => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.auth.user.id);

    const [processing, toggleProcessing] = useState(false);
    const [subscribeApiResponseIcon, setSubscribeApiResponseIcon] =
        useState("");
    const [subscribeApiResponseMessage, setSubscribeApiResponseMessage] =
        useState("");

    const [subscribed, setSubcribed] = useState(false);
    const [paymented, setPaymented] = useState(false);

    const [showModal, toggleShowModal] = useState(false);
    const [paymentModal, togglePaymentModal] = useState(false);
    const [optionsCeremonyId, setOptionsCeremonyId] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState({});

    const [retreatOptions, setRetreatOptions] = useState(
        retreat?.options_ceremony || []
    );
    const [totalPrice, setTotalPrice] = useState(0);

    const [totalBasePrice, setTotalBasePrice] = useState(0);

    const handleOptions = (e) => {
        const { value, checked } = e.target;

        const selectedOptionPrice = retreatOptions.filter(
            (item) => item?.option_ceremony_id === +value
        )[0];

        if (checked) {
            setTotalPrice((prev) => {
                if (
                    selectedOptionPrice?.price === 0 ||
                    selectedOptionPrice?.price == null
                ) {
                    return prev + retreat?.ticket_price;
                } else {
                    return prev + selectedOptionPrice?.price;
                }
            });
            setTotalBasePrice((prev) => prev + selectedOptionPrice?.base_price);
        } else {
            setTotalPrice((prev) => {
                if (
                    selectedOptionPrice?.price === 0 ||
                    selectedOptionPrice?.price == null
                ) {
                    return prev - retreat?.ticket_price;
                } else {
                    return prev - selectedOptionPrice?.price;
                }
            });
            setTotalBasePrice((prev) => prev - selectedOptionPrice?.base_price);
        }

        setRetreatOptions((prevState) => {
            let prevStateArray = JSON.parse(JSON.stringify(prevState));
            const selectedOptionIndex = prevStateArray.findIndex(
                (item) => item.option_ceremony_id === +value
            );
            if (selectedOptionIndex > -1) {
                prevStateArray[selectedOptionIndex].is_subscribe =
                    !prevStateArray[selectedOptionIndex].is_subscribe;

                return prevStateArray;
            }
            return prevStateArray;
        });
        if (checked) {
            setOptionsCeremonyId([...optionsCeremonyId, Number(value)]);
        } else {
            const updateCeremonyIds = optionsCeremonyId.filter(
                (item) => item !== +value
            );
            setOptionsCeremonyId(updateCeremonyIds);
        }
    };

    const handleSubscribeSuccessful = async (paypalResponse) => {
        toggleProcessing(true);
        togglePaymentModal(false);

        try {
            const res = await notifyOnEventPaymentApprove(
                userId,
                retreat.event_id,
                paypalResponse,
                paymentDetails.reference_id
            );
            if (!subscribed) {
                const response = await subscribeRetreatById(
                    userId,
                    retreat.event_id,
                    retreat.ticket_price,
                    paymentDetails.reference_id,
                    optionsCeremonyId
                );
                if (response.data.status === "Success") {
                    setSubscribeApiResponseIcon("success");
                    setSubscribeApiResponseMessage(
                        "Retreat subscribed successfully!"
                    );
                    toggleShowModal(true);
                    dispatch(
                        updateSubscriptionStatusForRetreat({
                            retreatId: retreat.event_id,
                            status: true,
                        })
                    );
                }
            } else {
                if (res.data.status === "Success") {
                    setSubscribeApiResponseIcon("success");
                    setSubscribeApiResponseMessage(
                        "Retreat subscribed successfully!"
                    );
                    toggleShowModal(true);
                }
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            toggleProcessing(false);
        }
    };

    const handleUnsubscribeSuccessful = async () => {
        toggleProcessing(true);
        try {
            const response = await unsubscribeRetreatById(retreat.event_id);
            if (response.data.status === "Success") {
                setSubscribeApiResponseIcon("warning");
                setSubscribeApiResponseMessage(
                    "Retreat unsubscribed Successfully!"
                );
                toggleShowModal(true);
                dispatch(
                    updateSubscriptionStatusForRetreat({
                        retreatId: retreat.event_id,
                        status: false,
                    })
                );
            }
        } catch (err) {
            console.log(err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            toggleProcessing(false);
        }
    };

    const handleNotifySubscriptionCancel = async (paypalResponse) => {
        console.log({ paypalResponse });
        try {
            await notifyOnEventPaymentCancel(
                userId,
                retreat.event_id,
                paypalResponse.orderID
            );
        } catch (err) {
            console.log(err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        }
    };

    const handleSubscribeRetreatForPayment = async () => {
        toggleProcessing(true);
        const response = await subscribeRetreatById(
            userId,
            retreat.event_id,
            retreat.ticket_price,
            paymentDetails.reference_id,
            optionsCeremonyId
        );
        if (response.data.status === "Success") {
            setSubscribeApiResponseIcon("success");
            toggleProcessing(false);
            setSubscribeApiResponseMessage("Retreat subscribed successfully!");
            toggleShowModal(true);
            dispatch(
                updateSubscriptionStatusForRetreat({
                    retreatId: retreat.event_id,
                    status: true,
                })
            );
        }
    };

    const handleOnSubscribe = async () => {
        if (retreat.is_subscribe && !optionsCeremonyId.length) {
            toast.error("Retreat is already subscribed");
            return;
        }

        const res = await handleEventOptionsForPayment(
            retreat.event_id,
            optionsCeremonyId
        );
        setPaymentDetails(res?.data?.data?.payment);
        const is_payment = res?.data?.data?.is_payment;
        const is_subscribe = res?.data?.data?.is_subscribe;

        setPaymented(is_payment);
        setSubcribed(is_subscribe);

        if (is_payment && is_subscribe) {
            toast.success("This retreat and options are already subscribed");
        } else if (is_payment && !is_subscribe) {
            handleSubscribeRetreatForPayment();
        } else {
            togglePaymentModal(true);
        }
    };

    const handleOnUnsubscribe = async () => {
        handleUnsubscribeSuccessful();
    };

    const disableSubmission = () => {
        let disabled = true;
        retreatOptions?.map((c) => {
            if (!(c.is_subscribe && c.is_payment)) {
                disabled = false;
            }
        });
        return disabled;
    };

    const btnTextChange = () => {
        let change = true;

        retreatOptions?.map((c) => {
            if (!(c.is_subscribe && c.is_payment)) {
                if (c.seats) {
                    change = false;
                }
            }
        });

        return change;
    };

    const reFactortitle = (
        <div className={styles.reFactortitle}>
            <span>{retreat.start_event_date}</span>
            <span style={{ textAlign: "center" }}> -</span>
            <span>{retreat.end_event_date}</span>
        </div>
    );

    return (
        <>
            <div className={cn(classSection, styles.section)}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.wrapper}>
                        <Details
                            className={styles.details}
                            description={retreat.Description}
                        />
                        <Receipt
                            className={styles.receipt}
                            items={[
                                {
                                    title: reFactortitle,
                                    category: "Date",
                                    icon: "calendar",
                                },
                                {
                                    title: `${
                                        retreat.numerOfSeatsLeft < 1
                                            ? "Booked Out"
                                            : retreat.numerOfSeatsLeft +
                                              ` ${
                                                  retreat.numerOfSeatsLeft > 1
                                                      ? " Places Available"
                                                      : "Place Available"
                                              }`
                                    } `,
                                    category: "Tickets",
                                    // icon: "ticket",
                                },
                            ]}
                            priceActual={
                                retreat.ticket_price && retreat.ticket_price > 0
                                    ? `EUR ${retreat.ticket_price.toLocaleString()}`
                                    : "Free"
                            }
                        >
                            <section className={styles.box}>
                                {/* {retreat.is_subscribe && retreat.is_payment ? (
									<button
										className={cn("button button-brown", styles.button)}
										onClick={handleOnUnsubscribe}
										disabled={processing}
									>
										{processing ? <Loader /> : "Unsubscribe"}
									</button>
								) : (
									<button
										className={cn("button button-brown", styles.button)}
										onClick={handleOnSubscribe}
										disabled={processing || retreat.numerOfSeatsLeft < 1}
									>
										{processing ? <Loader /> : "Subscribe"}
									</button>
								)} */}
                                <div className={cn(styles.optionsList)}>
                                    {retreatOptions?.map((opt) => (
                                        <>
                                            <div
                                                className={
                                                    styles.optionContainer
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.optionAction
                                                    }
                                                >
                                                    {
                                                        <label
                                                            className={
                                                                styles.checkbox
                                                            }
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                className={
                                                                    styles.input
                                                                }
                                                                name="selected_options"
                                                                disabled={
                                                                    (opt.is_payment &&
                                                                        opt.is_subscribe) ||
                                                                    retreat?.is_ceremony_outdated ||
                                                                    (opt.seats
                                                                        ? opt.seats &&
                                                                          opt.seats_left <=
                                                                              0
                                                                        : retreat.numerOfSeatsLeft <
                                                                          1)
                                                                }
                                                                checked={
                                                                    opt.is_subscribe
                                                                }
                                                                onChange={
                                                                    handleOptions
                                                                }
                                                                value={
                                                                    opt.option_ceremony_id
                                                                }
                                                            />
                                                            <span
                                                                className={
                                                                    styles.inner
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles.tick
                                                                    }
                                                                ></span>
                                                            </span>
                                                        </label>
                                                    }
                                                </div>
                                                <div className={styles.option}>
                                                    {opt.title} |{" "}
                                                    {retreat?.currency || "€"}
                                                    {opt.base_price} -{" "}
                                                    {retreat?.currency || "€"}
                                                    {opt.price
                                                        ? opt.price
                                                        : retreat.ticket_price}{" "}
                                                    {opt.seats
                                                        ? opt.seats_left
                                                            ? `${`
                                                            ${opt.seats_left} 
                                                            ${
                                                                opt.seats_left >
                                                                1
                                                                    ? "Places available"
                                                                    : "Place available"
                                                            }`}`
                                                            : "Booked Out"
                                                        : retreat.numerOfSeatsLeft &&
                                                          retreat.numerOfSeatsLeft >
                                                              0
                                                        ? `${`
                                                            ${
                                                                retreat.numerOfSeatsLeft
                                                            } 
                                                            ${
                                                                retreat.numerOfSeatsLeft >
                                                                1
                                                                    ? "Places available"
                                                                    : "Place available"
                                                            }`}`
                                                        : "Booked Out"}
                                                    {"   "}({opt.start_date} -{" "}
                                                    {"   "}
                                                    {opt.end_date})
                                                </div>
                                            </div>

                                            <br />
                                        </>
                                    ))}
                                </div>{" "}
                                <br />
                                {optionsCeremonyId.length ? (
                                    <div className={styles.totalPriceContainer}>
                                        <p></p>
                                        <p className={styles.totalPrice}>
                                            Deposit Amount:{" "}
                                            {retreat?.currency || "€"}
                                            {totalPrice}
                                        </p>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {optionsCeremonyId.length ? (
                                    <div className={styles.totalPriceContainer}>
                                        <p></p>
                                        <p className={styles.totalPrice}>
                                            Amount To Be Paid:{" "}
                                            {retreat?.currency || "€"}
                                            {totalBasePrice}
                                        </p>
                                    </div>
                                ) : (
                                    ""
                                )}
                                <button
                                    className={cn(
                                        "button button-brown",
                                        styles.button
                                    )}
                                    onClick={handleOnSubscribe}
                                    disabled={
                                        processing ||
                                        (disableSubmission() &&
                                            retreat.is_subscribe) ||
                                        (retreat.numerOfSeatsLeft < 1 &&
                                            !optionsCeremonyId.length)
                                    }
                                >
                                    {processing ? (
                                        <Loader />
                                    ) : btnTextChange() &&
                                      retreat.is_subscribe ? (
                                        "Your place is saved"
                                    ) : (
                                        "Join"
                                    )}
                                </button>
                            </section>
                        </Receipt>
                    </div>
                    {maps}
                </div>
            </div>
            <NotificationModal
                visibility={showModal}
                onClose={() => {
                    toggleShowModal(false);
                    window.location.reload();
                }}
                outerClassName={styles.infoModal}
                status={subscribeApiResponseIcon}
                message={subscribeApiResponseMessage}
            />
            <PaymentModal
                visibility={paymentModal}
                outerClassName={styles.paymentModalContainer}
                onClose={() => togglePaymentModal(false)}
                onApprove={handleSubscribeSuccessful}
                userId={userId}
                referenceId={paymentDetails.reference_id}
                price={paymentDetails.total_price}
                items={paymentDetails.items}
                onCancel={handleNotifySubscriptionCancel}
                currency_code={paymentDetails?.currency_code}
            />
        </>
    );
};

export default Description;
