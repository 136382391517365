import axios from "./axios";

export const loginService = async (email, password) => {
    try {
        const formData = new FormData();

        formData.append("email", email);
        formData.append("password", password);
        formData.append("grant_type", "member_credentials");
        const response = await axios.post("/members/auth/login", formData);
        const access_token = response.data.data.access_token;
        axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        localStorage.setItem("jwt_access_token", access_token);
        localStorage.setItem(
            "user_data",
            JSON.stringify(response.data.data.user)
        );
        localStorage.setItem(
            "membership_configuration",
            JSON.stringify(response.data.data.membership_configuration)
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const resetPasswordService = async (email) => {
    try {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("grant_type", "member_credentials");
        const response = await axios.post(
            "members/frontend-members/reset-password",
            formData
        );

        return response;
    } catch (err) {
        throw err;
    }
};

export const changePasswordService = async (
    password,
    repeatPassword,
    token
) => {
    try {
        const formData = new FormData();
        formData.append("password", password);
        formData.append("password_repeat", repeatPassword);
        const response = await axios.post(
            "members/frontend-members/reset-password-change/" + token,
            formData
        );

        return response;
    } catch (err) {
        throw err;
    }
};

export const checkTokenExpiry = async (token) => {
    try {
        const formData = new FormData();
        formData.append("token", token);
        const response = await axios.post(
            "members/frontend-members/resetPasswordTokenExpiryCheck",
            formData
        );

        return response;
    } catch (err) {
        throw err;
    }
};

export const getToken = async (token, email) => {
    try {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("token", token);
        const response = await axios.post(
            "/members/auth/getTokenValid",
            formData
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const registerInvitationService = async (token, refCode) => {
    try {
        const response = await axios.get(
            `/members/frontend-members/get-referral-invitee?ref_code=${refCode}&token=${token}`
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const registerService = async (userData, token, refCode) => {
    try {
        const formData = new FormData();
        formData.append("origin", "rest");
        formData.append("email", userData.email);
        formData.append("password", userData.password);
        formData.append("first_name", userData.firstName);
        formData.append("last_name", userData.lastName);
        // formData.append("question-1", "Why do you want to join ?");
        formData.append("answer-1", "");
        // formData.append("question-2", "Are you married ?");
        formData.append("answer-2", "");
        formData.append(
            "is_newsletter_subscriber",
            userData.is_newsletter_subscriber
        );
        formData.append("token", token);
        formData.append("ref_code", refCode);
        formData.append("country_id", userData.country_id);
        const response = await axios.post(
            `/members/member-accounts/add`,
            formData
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const updateUserDetailsService = async (userId, userData) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        formData.append("id", userId);
        formData.append("first_name", userData.firstName);
        formData.append("last_name", userData.lastName);
        formData.append("company_name", userData.companyName);
        formData.append("street_line_1", userData.address1);
        formData.append("street_line_2", userData.address2);
        formData.append("phone_number", userData.phone);
        const response = await axios.post(
            `/members/member-accounts/updateUserAccountInfo`,
            formData,
            { headers }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const updatePassword = async (
    userId,
    newPassword,
    confirmPassword,
    oldPassword
) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        formData.append("id", userId);
        formData.append("new_password", newPassword);
        formData.append("confirm_password", confirmPassword);
        formData.append("old_password", oldPassword);
        const response = await axios.post(
            `/members/member-accounts/updateUserPassword`,
            formData,
            { headers }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const setInviteByEmail = async (firstName, lastName, email) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("email", email);
        const response = await axios.post(
            `/members/frontend-members/sendInvitteeEmail`,
            formData,
            { headers }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const setInviteByEmailBulk = async (formData) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const response = await axios.post(
            `/members/frontend-members/sendInvitteeEmailUptoFivePersons`,
            formData,
            { headers }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const subscribeToNewsletter = async (email) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        formData.append("email", email);
        const response = await axios.post(
            `/members/FrontendNewsLetterSubscribers/newsletterEmailSend`,
            formData,
            { headers }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const getCurrentsubscribeToNewsletter = async (email) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const response = await axios.get(
            `/members/FrontendNewsLetterSubscribers/getCurrentNewsLetterSubscriberEmail`,
            { headers }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const changeStatusOfNewsletterSubscription = async (status) => {
    const access_token = localStorage.getItem("jwt_access_token");
    let currentSubscriber = "";
    const res = await getCurrentsubscribeToNewsletter();
    if (res.status === 200) {
        currentSubscriber = res?.data?.data;
    }
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    try {
        const formData = new FormData();
        formData.append("subscribe", status);
        formData.append("email", currentSubscriber);
        const response = await axios.post(
            `/members/FrontendNewsLetterSubscribers/getSubscribeOrUnSubscriber`,
            formData,
            { headers }
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const getNewsLetterSubscriberOrNot = async (status) => {
    const access_token = localStorage.getItem("jwt_access_token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    let currentSubscriber = "";
    const res = await getCurrentsubscribeToNewsletter();
    if (res.status === 200) {
        currentSubscriber = res?.data?.data;
        if (currentSubscriber) {
            try {
                const formData = new FormData();
                formData.append("email", currentSubscriber);
                const response = await axios.post(
                    `/members/FrontendNewsLetterSubscribers/getNewsLetterSubscriberOrNot`,
                    formData,
                    { headers }
                );
                return response;
            } catch (err) {
                throw err;
            }
        }
    }
};

export const logoutService = async () => {
    localStorage.removeItem("jwt_access_token");
    localStorage.removeItem("user_data");
    localStorage.removeItem("membership_configuration");
    delete axios.defaults.headers.common.Authorization;
};
