import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ceremoniesList: [],
    subscribedCeremoniesList: [],
    favoriteCeremoniesList: [],
    selectedCeremony: null,
};

const ceremoniesSlice = createSlice({
    name: "Ceremonies",
    initialState,
    reducers: {
        saveCeremoniesList: (state, action) => {
            state.ceremoniesList = action.payload;
        },
        saveSubscribedCeremoniesList: (state, action) => {
            state.subscribedCeremoniesList = action.payload;
        },
        saveFavoriteCeremoniesList: (state, action) => {
            state.favoriteCeremoniesList = action.payload;
        },
        saveSelectedCeremony: (state, action) => {
            state.selectedCeremony = action.payload;
        },
        updateSubscriptionStatusForCeremony: (state, action) => {
            // const ceremonyId = action.payload.ceremonyId;
            const status = action.payload.status;
            state.selectedCeremony.is_subscribe = status;
            state.selectedCeremony.is_payment = status;
            if (status) {
                state.selectedCeremony.numerOfSeatsLeft =
                    state.selectedCeremony.numerOfSeatsLeft - 1;
            } else if (!status) {
                state.selectedCeremony.numerOfSeatsLeft =
                    state.selectedCeremony.numerOfSeatsLeft + 1;
            }
        },
        updateFavoriteStatusForCeremony: (state, action) => {
            const ceremonyId = action.payload.eventId;
            if (state.selectedCeremony) {
                state.selectedCeremony.is_favorite = action.payload.status;
            }
            const indexOfCeremony = state.ceremoniesList.findIndex(
                (ceremony) => ceremony.event_id === ceremonyId
            );

            if (indexOfCeremony > -1) {
                state.ceremoniesList[indexOfCeremony].is_favorite =
                    action.payload.status;
            }
        },
    },
});

export const {
    saveCeremoniesList,
    saveSubscribedCeremoniesList,
    saveFavoriteCeremoniesList,
    saveSelectedCeremony,
    updateSubscriptionStatusForCeremony,
    updateFavoriteStatusForCeremony,
} = ceremoniesSlice.actions;

export default ceremoniesSlice.reducer;
