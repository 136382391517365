import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";

import styles from "./Header.module.sass";
import Notification from "./Notification";
import User from "./User";
import Icon from "../Icon";
import Modal from "../Modal";
import Login from "components/Login";

import Dropdown from "themeComponents/Dropdown";

const dashboardNavigationOptions = [
	{
		title: "Ceremonies",
		url: "#ceremonies",
		icon: "email",
	},
	{
		title: "Articles",
		url: "#articles",
		icon: "comment",
	},
	{
		title: "Retreats",
		url: "#retreats",
		icon: "comment",
	},
];

const protectedettingsMenuOptions = [
	{
		menu: [
			{
				title: "Ceremonies",
				icon: "home",
				url: "/",
			},
			{
				title: "Bookings",
				icon: "ticket",
				url: "/bookings",
			},
			{
				title: "Wishlists",
				icon: "heart",
				url: "/wishlists",
			},
		],
	},
];

const settingsMenuOptions = [
	{
		menu: [
			{
				title: "Ceremonies",
				icon: "home",
				url: "/",
			},
		],
	},
];

const Header = ({ separatorHeader, wide, notAuthorized }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const authState = useSelector((state) => state.auth.authState);
	const membershipStatus = useSelector((state) => state?.auth.membershipConfiguration?.is_membership_subscribed);

	const [visibleNav, setVisibleNav] = useState(false);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		const currentOption = dashboardNavigationOptions.filter((item) => item.url === location.pathname);

		if (currentOption.length) {
			setVisibleNav(currentOption[0].title);
		}
	}, []);

	return (
		<>
			<div className={cn(styles.header, { [styles.headerBorder]: separatorHeader }, { [styles.wide]: wide })}>
				<div className={cn("container", styles.container)}>
					<Link
						className={styles.logo}
						to="/"
					>
						<h3 className={styles.title}>
							Mother <br /> Club
						</h3>
					</Link>
					{/* <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
						<Dropdown
							className={styles.drowdown}
							items={dashboardNavigationOptions}
							activeValue={visibleNav}
							setValue={setVisibleNav}
						/>
					</div> */}
					<div className={styles.headerSection}>
						{/* <Notification className={styles.notification} /> */}
						{!authState ? (
							<button
								className={styles.login}
								onClick={() => setVisible(true)}
							>
								<Icon
									name="user"
									size="24"
								/>
							</button>
						) : (
							<User
								className={styles.user}
								items={membershipStatus ? protectedettingsMenuOptions : settingsMenuOptions}
							/>
						)}
						{/* <button
							className={cn(styles.burger, { [styles.active]: visibleNav })}
							onClick={() => setVisibleNav(!visibleNav)}
						></button> */}
					</div>
				</div>
			</div>

			<Modal
				visible={visible}
				onClose={() => setVisible(false)}
			>
				<Login />
			</Modal>
		</>
	);
};

export default Header;
