import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "themeComponents/Icon";

import { logoutService } from "utils/authService";
import { removeUserData, toggleAuthState } from "store/authSlice";

const User = ({ className, items }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);

	const [visible, setVisible] = useState(false);
	const [processing, toggleProcessing] = useState(false);

	const logoutHandler = async (e) => {
		e.preventDefault();
		toggleProcessing(true);
		try {
			await logoutService();
			window.history.go("/");
			// dispatch(toggleAuthState(false));
			// dispatch(removeUserData());
		} catch (err) {
			console.log("err", err);
		} finally {
			toggleProcessing(false);
		}
	};

	return (
		<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
			<div className={cn(styles.user, className, { [styles.active]: visible })}>
				<button
					className={styles.head}
					onClick={() => setVisible(!visible)}
				>
					{/* {user.profile_img ? (
						<img
							src={user.profile_img}
							alt="Avatar"
						/>
					) : (
						<div className={styles.svgContainer}>
							<Icon
								name="user"
								size="24"
							/>
						</div>
					)} */}
					<div className={styles.svgContainer}>
						<Icon
							name="user"
							size="24"
						/>
					</div>
				</button>
				<div className={styles.body}>
					<div className={styles.group}>
						{items.map((item, index) => (
							<div
								className={styles.menu}
								key={index}
							>
								{item.menu.map((x, index) => (
									<NavLink
										className={styles.item}
										activeClassName={styles.active}
										to={x.url}
										onClick={() => setVisible(!visible)}
										key={index}
									>
										<div className={styles.icon}>
											<Icon
												name={x.icon}
												size="24"
											/>
										</div>
										<div className={styles.text}>{x.title}</div>
									</NavLink>
								))}
							</div>
						))}
					</div>

					<div className={styles.btns}>
						<NavLink
							className={cn("button button-small button-brown", styles.button)}
							activeClassName={styles.active}
							to="/account-settings"
							onClick={() => setVisible(!visible)}
						>
							Account
						</NavLink>
						<button
							className={cn("button-stroke button-small", styles.button)}
							onClick={logoutHandler}
						>
							{processing ? "Logging out" : "Log out"}
						</button>
					</div>
				</div>
			</div>
		</OutsideClickHandler>
	);
};

export default User;
