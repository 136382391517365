import React, { useState } from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "components/Icon";

const TextInput = ({
    className,
    label,
    empty,
    view,
    type,
    error = false,
    errorMessage,
    textArea = false,
    ...props
}) => {
    const [viewActive, setViewActive] = useState(false);
    return (
        <div
            className={cn(
                styles.field,
                { [styles.empty]: empty },
                { [styles.view]: view },
                className
            )}
        >
            {label && <div className={styles.label}>{label}</div>}
            <div className={styles.wrap}>
                {textArea ? (
                    <textarea
                        className={cn(styles.textArea, {
                            [styles.error]: error,
                        })}
                        type={viewActive ? "text" : type}
                        {...props}
                    />
                ) : (
                    <input
                        className={cn(styles.input, { [styles.error]: error })}
                        type={viewActive ? "text" : type}
                        {...props}
                    />
                )}
                {view && (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setViewActive(!viewActive);
                        }}
                        className={styles.toggle}
                    >
                        <Icon
                            name={viewActive ? "eye-closed" : "eye"}
                            size="24"
                        />
                    </button>
                )}
            </div>
            {error && errorMessage && (
                <p className={styles.errorMessage}>
                    <Icon name="warning" className={styles.errorIcon} />
                    {errorMessage}
                </p>
            )}
        </div>
    );
};

export default TextInput;
