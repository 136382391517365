import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";

import styles from "./Login.module.sass";
import TextInput from "themeComponents/TextInput";
import Loader from "themeComponents/Loader";
import Radio from "themeComponents/Radio";
import { loginService } from "utils/authService";
import { saveUserData, toggleAuthState, saveUserMembershipConfiguration } from "store/authSlice";

const schema = yup.object().shape({
	email: yup.string().email("Enter correct Email Address.").required("You must enter an Email Address.").typeError("Only correct Email format is allowed."),
	password: yup.string().required("You must enter a Password."),
});

const Login = ({ receivedEmail, onClose, setResetPassword=()=>{} }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [processing, toggleProcessing] = useState(false);
    const [confirmPhone, setConfirmPhone] = useState(true);

    const methods = useForm({
        mode: "onTouched",
        defaultValues: {
            email: "",
            password: "",
        },
        resolver: yupResolver(schema),
    });
    const {
        reset,
        watch,
        control,
        formState: { isValid, isDirty },
        getValues,
        setValue,
    } = methods;
    const form = watch();

    const loginFormSubmitHandler = async (e) => {
        e.preventDefault();
        const allValues = getValues();
        toggleProcessing(true);
        try {
            const response = await loginService(
                allValues.email,
                allValues.password
            );
            const user = response.data.data.user;
            const membershipConfiguration =
                response.data.data.membership_configuration;
            if (
                membershipConfiguration &&
                !membershipConfiguration.is_membership_subscribed
            ) {
                dispatch(toggleAuthState(true));
                dispatch(saveUserData(user));
                dispatch(
                    saveUserMembershipConfiguration(membershipConfiguration)
                );
                navigate("/account-settings");
            } else {
                window.history.go("/");
            }
        } catch (err) {
            console.log("err", err);
            if (err.response) {
                toast.error(err.response.data.message);
            }
        } finally {
            toggleProcessing(false);
        }
    };

    useEffect(() => {
        setValue("email", receivedEmail);
    }, [receivedEmail]);

    return (
        <div className={cn(styles.login)}>
            <div className={styles.item}>
                <div className={cn("h3", styles.title)}>Sign in</div>

                <form className={styles.form} onSubmit={loginFormSubmitHandler}>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                {...field}
                                className={styles.field}
                                type="text"
                                placeholder="Email Address"
                                required
                                error={error}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                {...field}
                                className={styles.field}
                                type="password"
                                placeholder="Password"
                                required
                                view
                                error={error}
                                errorMessage={error?.message}
                            />
                        )}
                    />
                    <button
                        className={cn("button button-brown", styles.button)}
                        type="submit"
                        disabled={processing || !isValid || !isDirty}
                    >
                        {processing ? <Loader color="white" /> : "Sign in"}
                    </button>
                </form>

                <div className={styles.foot}>
                    <a
                        className={styles.password}
                        onClick={() => setResetPassword(true)}
                    >
                        Forgot password?
                    </a>
                </div>
            </div>

            {false && (
                <div className={styles.item}>
                    <div className={cn("h3", styles.title)}>
                        Let’s confirm it’s really you
                    </div>
                    <div className={styles.info}>
                        Help us secure your account. Please complete the
                        verifications below
                    </div>
                    <form className={styles.form}>
                        <div className={styles.variants}>
                            <Radio
                                className={styles.radio}
                                name="confirm"
                                value={confirmPhone}
                                onChange={() => setConfirmPhone(true)}
                                content="Get the code by text message (SM) at +1 234 567 890"
                            />
                            <Radio
                                className={styles.radio}
                                name="confirm"
                                value={!confirmPhone}
                                onChange={() => setConfirmPhone(false)}
                                content="Get the code by email at tranm••••••••••••@gm•••.com"
                            />
                        </div>
                        <button className={cn("button", styles.button)}>
                            Continue
                        </button>
                    </form>
                </div>
            )}

            {false && (
                <div className={styles.item}>
                    <div className={cn("h3", styles.title)}>
                        Enter your security code
                    </div>
                    <div className={styles.info}>
                        We texted your code to +1 234 567 890
                    </div>
                    <form className={styles.form}>
                        <div className={styles.code}>
                            <div className={styles.number}>
                                <input type="tel" />
                            </div>
                            <div className={styles.number}>
                                <input type="tel" />
                            </div>
                            <div className={styles.number}>
                                <input type="tel" />
                            </div>
                            <div className={styles.number}>
                                <input type="tel" />
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Login;
