import cn from "classnames";

import Modal from "components/Modal";
import Icon from "components/Icon";

import styles from "./NotificationModal.module.sass";

const NotificationModal = ({ visibility, onClose, outerClassName, status = "warning", message }) => {
	return (
		<Modal
			visible={visibility}
			onClose={onClose}
			outerClassName={cn(styles.notificationModal, outerClassName)}
		>
			{status === "success" ? (
				<Icon
					name="success"
					className={styles.successIcon}
				/>
			) : (
				<Icon
					name="warning"
					className={styles.warningIcon}
				/>
			)}
			<p>{message}</p>
		</Modal>
	);
};

export default NotificationModal;
