import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import styles from "./Form.module.sass";
import TextInput from "themeComponents/TextInput";
import Loader from "themeComponents/Loader";
import Checkbox from "themeComponents/Checkbox";
import axios, { all } from "axios";

const passwordShapeErr =
    "Password must be atleast 8 characters long. And should include one capital letter, one special character and one number.";

const schema = yup.object().shape({
    firstName: yup.string().required("You must enter First name."),
    lastName: yup.string().required("You must enter Last name."),
    email: yup
        .string()
        .email("Enter correct email address.")
        .required("You must enter an Email Address."),
    password: yup
        .string()
        .required("You must enter a Password.")
        .matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
            passwordShapeErr
        ),
    // question1: yup.string().required("This field is required."),
    // question2: yup.string().required("This field is required."),
    country_id: yup
        .number()
        .required()
        .test("required", "You must select a country.", function (value) {
            return value !== -1;
        }),
});

const RegisterForm = ({ onSubmit, processing, newUser, referralName }) => {
    const [countries, setCountries] = useState([]);

    const methods = useForm({
        mode: "onTouched",
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            question1: "",
            question2: "",
            is_newsletter_subscriber: false,
            country_id: -1,
        },
        resolver: yupResolver(schema),
    });
    const {
        reset,
        watch,
        control,
        formState: { isValid, isDirty, errors },
        getValues,
        setValue,
    } = methods;
    const form = watch();

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const allValues = getValues();

        onSubmit(allValues, () => reset());
    };

    useEffect(() => {
        if (newUser) {
            setValue("firstName", newUser.first_name);
            setValue("lastName", newUser.last_name);
            setValue("email", newUser.email);
            setValue("country_id", newUser.country);
        }
    }, [newUser]);

    const fetchCountries = async () => {
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: "https://api-mother.pwr.dev/events/core-events/getAllCountry",
            headers: {
                // Authorization: "Bearer 77626465c27398ab53cafcdcc386eb68",
                Cookie: "CAKEPHP=f8abdjh5lkbe493g1f9km0plgv",
            },
        };

        axios
            .request(config)
            .then((response) => {
                const res = response.data;
                const { data } = res;

                const addPlaceHolder = {
                    id: -1,
                    Name: "Select Your Country",
                };

                data.unshift(addPlaceHolder);

                setCountries(data);

                if (data.length) {
                    setValue("country_id", data[0].id);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    return (
        <div className={cn(styles.login)}>
            <div className={styles.item}>
                <form className={styles.form} onSubmit={handleFormSubmit}>
                    <TextInput
                        value={referralName}
                        className={styles.field}
                        type="text"
                        placeholder="Reffered By"
                        required
                        disabled
                    />

                    <Controller
                        name="firstName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                {...field}
                                className={styles.field}
                                type="text"
                                placeholder="Enter your First name"
                                required
                                error={error}
                                errorMessage={error?.message}
                            />
                        )}
                    />

                    <Controller
                        name="lastName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                {...field}
                                className={styles.field}
                                type="text"
                                placeholder="Enter your Last name"
                                required
                                error={error}
                                errorMessage={error?.message}
                            />
                        )}
                    />

                    <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                {...field}
                                className={styles.field}
                                type="text"
                                placeholder="Enter your email"
                                required
                                disabled
                                error={error}
                                errorMessage={error?.message}
                            />
                        )}
                    />

                    <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                {...field}
                                className={styles.field}
                                type="password"
                                view
                                placeholder="Enter your Password"
                                required
                                error={error}
                                errorMessage={error?.message}
                            />
                        )}
                    />

                    <Controller
                        name="country_id"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <select
                                {...field}
                                className={cn(styles.input_select)}
                            >
                                {countries?.map((country) => (
                                    <option
                                        key={country?.id}
                                        value={country?.id}
                                    >
                                        {country?.Name}
                                    </option>
                                ))}
                            </select>
                        )}
                    />

                    {/* <Controller
                        name="question1"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                {...field}
                                className={styles.field}
                                type="text"
                                placeholder="Why do you want to join Mothers club?"
                                required
                                error={error}
                                errorMessage={error?.message}
                            />
                        )}
                    /> */}

                    {/* <Controller
                        name="question2"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                {...field}
                                className={styles.field}
                                type="text"
                                placeholder="Are you married?"
                                required
                                error={error}
                                errorMessage={error?.message}
                            />
                        )}
                    /> */}

                    <Controller
                        name="is_newsletter_subscriber"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <Checkbox
                                {...field}
                                className={styles.checkbox}
                                content="Select to subscribe to our newsletter."
                            />
                        )}
                    />

                    <button
                        className={cn("button", styles.button)}
                        type="submit"
                        disabled={processing || !isValid || !isDirty}
                    >
                        {processing ? <Loader color="white" /> : "Next"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default RegisterForm;
